import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";
import PageHeader from "../components/pageUI/PageHeader";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ServerPaginationDataGrid from "../components/pageUI/ServerPaginationDataGrid";
import { useSelector } from "react-redux";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";
import axios from "axios";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import Text from "../components/typographyUI/Text";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";

const RsvpAnalyse = () => {
  const [loading, setLoading] = useState(false);

  const dayName = [
    "",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const { outletId } = useSelector((state) => state.auth.user);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const apiFormatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return !dateString ? "" : `${year}/${month}/${day}`;
  };

  const generateExcel = (_jsonData = []) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("RSVP");

    // Adding header row
    if (_jsonData.length > 0) {
      const headers = Object.keys(_jsonData[0]);
      worksheet.addRow(headers);
    }

    // Adding data rows
    _jsonData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Apply bold font to the header row
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.font = { bold: true };
    });

    // Adjusting column widths
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    workbook.xlsx
      .writeBuffer()
      .then((excelBuffer) => {
        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(data, "RSVP_Data.xlsx");
      })
      .catch((error) => {
        console.error("Error generating Excel file:", error);
      });
  };

  const columns = useMemo(() => [
    {
      field: "rsvpdate",
      headerName: "Date",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{row?.date}</Text>
        </>
      ),
    },
    {
      field: "day",
      headerName: "Day",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{dayName[row?.dayNumber]}</Text>
        </>
      ),
    },
    {
      field: "RSVPCount",
      headerName: "RSVP Count",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{row?.rsvpCount}</Text>
        </>
      ),
    },
    {
      field: "mealcount",
      headerName: "Meal Count",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{row?.mealCount}</Text>
        </>
      ),
    },
    {
      field: "difference",
      headerName: "Difference",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{row?.difference}</Text>
        </>
      ),
    },
  ]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"Mess Report"}
          subTitle={"View and download your mess report"}
        />
        <StackRow
          gap={"10px"}
          between
          center
          marginBottom="10px"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {/* <CustomSearchTextField
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        /> */}

          <StackRow center gap="14px">
            <DateRangePicker
              setFrom={setStartDate}
              setTo={setEndDate}
              fromDate={startDate}
              toDate={endDate}
            />

            <CustomButton
              textColor="#4F46E5"
              bgColor="#EEF2FF"
              variant="outlined"
              startIcon={<CloudDownloadOutlinedIcon />}
              disabled={!Boolean(startDate && endDate)}
              onClick={() => {
                setLoading(true);
                axios
                  .get(
                    process.env.REACT_APP_BACKEND_BASE_URL +
                      `mess/dashboard/getReportData/${outletId}?startDate=${startDate}&endDate=${endDate}`
                  )
                  .then((res) => {
                    // console.log("dataaa ",res?.data?.data);
                    generateExcel(res.data.data);
                  })
                  .catch((error) => {})
                  .finally(() => setLoading(false));
              }}
            >
              {loading ? "Generating..." : "Generate Excel"}
            </CustomButton>
          </StackRow>
        </StackRow>

        {outletId && (
          <CustomDataGrid
            rowId="date"
            name="rsvp-table"
            labelName="rsvp"
            height={"500px"}
            columns={columns}
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              "mess/dashboard/getReportData/" +
              outletId
            }
            noActions
          />
        )}
      </Box>
    </motion.div>
  );
};

export default RsvpAnalyse;
