import React, { useEffect, useState } from "react";
import Headline2 from "../components/typographyUI/Headline2";
import Text from "../components/typographyUI/Text";
import {
  Grid,
  Stack,
  Box,
  Divider,
  ButtonGroup,
  LinearProgress,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import DashboardCard from "../components/pageUI/dashboard/DashboardCard";
import PaperBox from "../components/layoutUI/PaperBox";
import Headline4 from "../components/typographyUI/Headline4";
import LineChart from "../components/chartUI/LineChart";
import Headline3 from "../components/typographyUI/Headline3";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Publish from "./Publish";
import axios from "axios";
import { useQuery } from "react-query";
import BarChart2 from "../components/chartUI/BarChart2";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import FoodImg from "../assets/images/image 4.png";
import ItemCard from "../components/pageUI/ItemCard";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import CustomSwitch from "../components/formUI/CustomSwitch";
import RestoListFilter from "../components/pageUI/filters/RestoListFilter";
import { updatePetPoojaConfigs } from "../Services/menuService";
import { authLogin } from "../Redux Store/Slices/auth";
import { toast } from "react-toastify";
import {
  updateOutletData,
  updateOutletOpenStatus,
} from "../Services/generalService";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import { useMemo } from "react";
import PieChart from "../components/chartUI/PieChart";

const Dashboard = () => {
  const outletDetails = useSelector((state) => state.auth.user);
  const [outletId, setOutletId] = useState(outletDetails.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const { user } = useSelector((state) => state.auth);
  const isAuthId = !!user?.outletStaffData?.outletStaffAuthUId;

  useEffect(() => {}, []);

  const today = new Date();
  const dispatch = useDispatch();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      id: 1,
      name: "Total Revenue",
      amount: 777,
      growth: 50,
    },
    {
      id: 2,
      name: "Total Customers",
      amount: 999,
      growth: 50,
      noRupeeSymbol: true,
    },
    {
      id: 3,
      name: "Total Orders",
      amount: 999,
      growth: 50,

      noRupeeSymbol: true,
    },
    {
      id: 4,
      name: "Total Menus",
      amount: 999,
      growth: 50,
      noRupeeSymbol: true,
    },
  ]);
  const [summaryData, setSummaryData] = useState([
    {
      id: 1,
      name: "Current Orders",
      amount: 777,
      growth: 50,
    },
    {
      id: 2,
      name: "Upcoming Orders",
      amount: 777,
      growth: 50,
    },
    {
      id: 3,
      name: "Cancelled Order",
      amount: 777,
      growth: 50,
    },
    {
      id: 4,
      name: "Refunded order",
      amount: 777,
      growth: 50,
    },
  ]);
  const [topMenu, setTopMenu] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [orderPaymentMethod, setOrderPaymentMethod] = useState([]);
  const [orders, setOrders] = useState([]);
  const [view, setView] = useState("Yearly");
  const [filtervalue, setFilterValue] = useState("Yearly");

  const matches = useMediaQuery("(max-width: 1352px)");

  const [isTimeExtendedLoading, setIsTimeExtendedLoading] = useState(false);

  const { isLoading, isFetching } = useQuery(
    ["dashbaordData", view, outletIdNew],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + `order/order/dashboardData`,
        {
          target_date:
            view === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
          outlet_id: outletIdNew,
          analyticalType:
            view === "Last Year"
              ? "year"
              : view.toLocaleLowerCase().slice(0, -2),
          isAdmin: isAuthId ? false : true,
        }
      ),
    {
      onSuccess: (res) => {
        let dashboardData = res?.data?.data;
        // console.log("Dash Data for order summery", dashboardData);
        setData([
          {
            id: 1,
            name: "Total Revenue",
            amount: dashboardData?.total_revenue,
            growth: 50,
            tooltipText: "Total sales of your outlet through MealPe",
          },
          {
            id: 2,
            name: "Total Customers",
            amount: dashboardData?.total_customers,
            growth: 50,
            tooltipText:
              " Number of customers who have place order through Mealpe",
            noRupeeSymbol: true,
          },
          {
            id: 3,
            name: "Total Orders",
            amount: dashboardData?.total_orders,
            tooltipText:
              "Total orders received through the app over a certain period of time",
            growth: 50,
            noRupeeSymbol: true,
          },
          {
            id: 4,
            name: "Average Order Value",
            amount: dashboardData?.average_order_value,
            tooltipText: "Total Revenue/Total number of Orders",
            growth: 50,
            noRupeeSymbol: true,
          },
        ]);
        setSummaryData([
          {
            id: 1,
            name: "Current Orders",
            amount: dashboardData?.current_order,
            growth: 50,
          },
          {
            id: 2,
            name: "Upcoming Orders",
            amount: dashboardData?.upcoming_order,

            growth: 50,
          },
          {
            id: 3,
            name: "Pre Paid Orders",
            amount: dashboardData?.total_online_orders,
            growth: 50,
          },
          {
            id: 4,
            name: "Cash On Delivery Orders",
            amount: dashboardData?.total_cash_orders,
            growth: 50,
          },
          // {
          //   id: 3,
          //   name: "Cancelled Order",
          //   amount: dashboardData?.total_cancalled,
          //   growth: 50,
          // },
          // {
          //   id: 4,
          //   name: "Refunded order",
          //   amount: dashboardData?.total_refund,
          //   growth: 50,
          // },
        ]);
      },
    }
  );

  const { isLoading: isLoading1, isFetching: isFetching1 } = useQuery(
    ["revenue-chart", view, outletIdNew],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "order/order/customerDineinPickupDeliveryOutlet",
        {
          targetDate:
            view === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
          outletId: outletIdNew,
          analyticaltype:
            view === "Last Year"
              ? "year"
              : view.toLocaleLowerCase().slice(0, -2),
        }
      ),
    {
      onSuccess: (res) => {
        // console.log("Dashboard data for Chart ", res?.data?.data);
        const chartData = [
          {
            id: "Delivery",
            label: "Delivery",
            value: res?.data?.data?.[0]?.deliverycount,
            color: "#9E77ED",
          },
          {
            id: "Dine In",
            label: "Dine In",
            value: res?.data?.data?.[0]?.dineincount,
            color: "#B692F6",
          },
          {
            id: "Take Away",
            label: "Take Away",
            value: res?.data?.data?.[0]?.pickupcount,
            color: "#F4EBFF",
          },
        ];

        const chartData1 = [
          {
            id: "Pre Paid Orders",
            label: "Pre Paid",
            value: res?.data?.data?.[0]?.total_online_orders,
            color: "#9E77ED",
          },
          {
            
            id: "Cash On Delivery Orders",
            label: "COD",
            value: res?.data?.data?.[0]?.total_cash_orders,
            color: "#F4EBFF",
          },
        ];

        // Example usage:
        // const currentDate = new Date(); // Get the current date
        // const revenueData = [
        //   {
        //     label: "2023-08-24",
        //     total_price: 19.609299999999998,
        //   },
        //   {
        //     label: "2023-08-25",
        //     total_price: 3206.0661,
        //   },
        // ];

        // const updatedDateDataArray = updateDateDataWithRevenueMonthly(
        //   currentDate,
        //   revenueData
        // );

        setRevenue(chartData);
        setOrderPaymentMethod(chartData1);
      },
    }
  );

  const { isLoading: isLoading2, isFetching: isFetching2 } = useQuery(
    ["orders-chart", view, outletIdNew],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "order/order/peakTimeAnalyticsOutlet",
        {
          outletId: outletIdNew,
          analyticalType:
            view === "Last Year"
              ? "year"
              : view.toLocaleLowerCase().slice(0, -2),
          target_date:
            view === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
        }
      ),
    {
      onSuccess: (res) => {
        const chartData = [];

        const timeLabels = [
          "12 AM",
          "01 AM",
          "02 AM",
          "03 AM",
          "04 AM",
          "05 AM",
          "06 AM",
          "07 AM",
          "08 AM",
          "09 AM",
          "10 AM",
          "11 AM",
          "12 PM",
          "01 PM",
          "02 PM",
          "03 PM",
          "04 PM",
          "05 PM",
          "06 PM",
          "07 PM",
          "08 PM",
          "09 PM",
          "10 PM",
          "11 PM",
        ];

        for (let i = 0; i < 24; i++) {
          const matchedData = res?.data?.data?.find(
            (hourData) => hourData.hour === i + 1
          );

          let dineIn = 0;
          let pickUp = 0;
          let delivery = 0;

          if (matchedData) {
            dineIn = matchedData?.dine_in_count;
            pickUp = matchedData?.pick_up_count;
            delivery = matchedData?.delivery_count;
          }

          chartData.push({
            country: timeLabels[i],
            "Dine In": dineIn,
            "Take Away": pickUp,
            Delivery: delivery,
          });
        }

        // // Example usage:
        // const currentDate = new Date(); // Get the current date
        // const revenueData = [
        //   {
        //     label: "2023-08-24",
        //     total_price: 19.609299999999998,
        //   },
        //   {
        //     label: "2023-08-25",
        //     total_price: 3206.0661,
        //   },
        // ];

        // const updatedDateDataArray = updateDateDataWithRevenueMonthly(
        //   currentDate,
        //   revenueData
        // );

        setOrders(chartData);
      },
    }
  );
  const { isLoading: isLoading3, isFetching: isFetching3 } = useQuery(
    ["top-menu", outletIdNew, filtervalue],
    () =>
      outletIdNew &&
      axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL + `order/order/topThreeMenuItem`,
        {
          analyticaltype:
            filtervalue == "Daily"
              ? "day"
              : filtervalue == "Last Year"
              ? "year"
              : filtervalue.toLocaleLowerCase().slice(0, -2),
          outletId: outletIdNew,
          targetDate:
            filtervalue === "Last Year"
              ? `${year - 1}-${month}-${day}`
              : formattedDate,
        }
      ),
    {
      onSuccess: (res) => {
        setTopMenu(res?.data?.data);
      },
    }
  );

  // async function onIsTimeExtendedChange(_value) {
  //   if (outletDetails?.outletId) {
  //     const postData = {
  //       isTimeExtended: _value
  //     }
  //     const updatePetPoojaConfigsResponse = await updatePetPoojaConfigs(postData, outletDetails?.outletId);

  //     if (updatePetPoojaConfigsResponse?.data?.success) {
  //       dispatch(authLogin({ ...outletDetails, ...postData }));
  //     }
  //   }
  // }

  const onIsTimeExtendedChange = (values) => {
    setIsTimeExtendedLoading(true);
    let postbody = {
      isOutletOpen: values,
      outletId: outletId,
    };
    updateOutletOpenStatus(postbody)
      .then((res) => {
        dispatch(authLogin({ ...outletDetails, isOutletOpen: values }));
        toast.success(
          // res?.data?.data?.[0]?.isOutletOpen
          //   ? "Time extended Successfully"
          //   : "The time extension has concluded"
         
          values
          ? "Outlet Open Successfully"
          : "Outlet Close Successfully"
        );
        setIsTimeExtendedLoading(false);
      })
      .catch((e) => {
        setIsTimeExtendedLoading(false);
        toast.error("something went wrong please try again!");
      });
  };

  // useEffect(() => {
  // }, [outletDetails])
  const columns = useMemo(() => [
    {
      field: "item",
      headerName: "Item",
      flex: 1,
      // minWidth: 150,
      // maxWidth: 300,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "8px",
            objectFit: "cover",
          }}
          img={row.item_image_url}
          sx={{
            height: 36,
          }}
          title={row?.itemname}
          titleSx={{ marginTop: "6px" }}
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      // minWidth: 100,
      maxWidth: 100,
      sortable: false,
      alignItems: "center",
      justifyContent: "center",
      renderCell: ({ row }) => <Text bold>{row?.total_count}</Text>,
    },
  ]);
  return (
    <>
      <>
        {outletDetails?.publishProcessingStep >= 3 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box
              sx={{
                height: "20px",
              }}
            >
              {(isFetching || isFetching2 || isFetching1 || isFetching3) && (
                <LinearProgress />
              )}
              {isTimeExtendedLoading && <LinearProgress />}
            </Box>
            <StackRow between center>
              <div>
                <Headline2>Dashboard</Headline2>
                <Text mb={"1.5rem"}>Welcome to MealPe</Text>{" "}
              </div>
              <StackRow gap="0.7rem" center>
                <CustomSwitch
                  value={Boolean(outletDetails?.isOutletOpen)}
                  label={"Accepting orders"}
                  isInternalSetState={false}
                  onChange={(_value) => {
                    console.log("values ", _value);
                    onIsTimeExtendedChange(_value);
                  }}
                />
                {/* <RestoListFilter
                  onClick={(label, id) => {
                    setOutletId(id);
                  }}
                /> */}
                {["Last Year", "Yearly", "Monthly", "Weekly"].map(
                  (v, index) => (
                    <CustomButton
                      key={index}
                      variant={v == view ? "contained" : "outlined"}
                      sx={{
                        filter: v != view ? "grayscale(1)" : "none",
                      }}
                      textContent={v}
                      onClick={() => {
                        setView(v);
                      }}
                    />
                  )
                )}
              </StackRow>
            </StackRow>

            {isLoading && isLoading1 && isLoading2 && isLoading3 ? (
              <Grid
                container
                spacing={"1.7rem"}
                rowSpacing={"2.8rem"}
                columnSpacing={"1.7rem"}
              >
                {" "}
                {new Array(4).fill(0).map((order, index) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <PaperBox padding={"2.28rem"}>
                        <Skeleton
                          height={"1.5rem"}
                          width={"60%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 2,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"2.5rem"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                          }}
                          animation="wave"
                        />
                      </PaperBox>
                    </Grid>
                  );
                })}
                <Grid item xs={12} md={12}>
                  <PaperBox
                    sx={{
                      mb: 4,
                    }}
                  >
                    <Box sx={{}}>
                      <Skeleton
                        height={"1.5rem"}
                        width={"10%"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 2,
                        }}
                        animation="wave"
                      />
                      {/* <Skeleton
                        height={"1.5rem"}
                        width={"100%"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 2,
                        }}
                        animation="wave"
                      /> */}
                      <Skeleton
                        height={"16.7rem"}
                        width={"100%"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                        }}
                        animation="wave"
                      />
                    </Box>
                  </PaperBox>
                  {/* <PaperBox>
                    <Skeleton
                      height={"1.5rem"}
                      width={"60%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 2,
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"1.5rem"}
                      width={"30%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 2,
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"16.7rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                      }}
                      animation="wave"
                    />
                  </PaperBox> */}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item md={3}>
                      <PaperBox
                        sx={{
                          mb: 4,
                          mr: 4,
                        }}
                      >
                        <StackRow between>
                          <Skeleton
                            height={"35px"}
                            width={"50%"}
                            sx={{
                              transform: "scale(1)",
                              transformOrigin: "0",
                              mb: 4,
                            }}
                            animation="wave"
                          />
                          <Skeleton
                            height={"35px"}
                            width={"30%"}
                            sx={{
                              transform: "scale(1)",
                              transformOrigin: "0",
                              mb: 1,
                            }}
                            animation="wave"
                          />
                        </StackRow>
                        <Skeleton
                          height={"50px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            margin: "auto",
                            mb: 3,
                          }}
                          animation="wave"
                        />
                        {/* <Divider
                      sx={{
                        margin: "18px 0",
                      }}
                    /> */}
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                        <Skeleton
                          height={"40px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Divider
                          sx={{
                            margin: "18px 0",
                          }}
                        />
                      </PaperBox>
                    </Grid>
                    <Grid item md={9}>
                      <Grid container>
                        <Grid item md={12}>
                          <PaperBox>
                            <Skeleton
                              height={"40px"}
                              width={"20%"}
                              sx={{
                                transform: "scale(1)",
                                transformOrigin: "0",
                                mb: 4,
                              }}
                              animation="wave"
                            />
                            <StackRow between>
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              <Skeleton
                                height={"8rem"}
                                width={"13rem"}
                                // variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                  mr: 2,
                                  flex: 1,
                                }}
                                animation="wave"
                              />
                              {/* <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack> */}
                            </StackRow>
                            {/* <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow> */}
                            {/* <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow> */}

                            {/* <Skeleton
                      height={"50px"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 4,
                      }}
                      animation="wave"
                    /> */}
                          </PaperBox>
                        </Grid>
                        <Grid item md={6}>
                          <PaperBox>
                            <Skeleton
                              height={"40px"}
                              width={"40%"}
                              sx={{
                                transform: "scale(1)",
                                transformOrigin: "0",
                                mb: 4,
                              }}
                              animation="wave"
                            />
                            <StackRow between>
                              <Skeleton
                                height={"15rem"}
                                width={"15rem"}
                                variant="circular"
                                sx={{
                                  transform: "scale(1)",
                                  transformOrigin: "0",
                                  mb: 4,
                                }}
                                animation="wave"
                              />
                            </StackRow>
                          </PaperBox>
                        </Grid>
                      </Grid>
                      {/* <PaperBox>
                    <Skeleton
                      height={"40px"}
                      width={"60%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 4,
                      }}
                      animation="wave"
                    />
                    <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow>
                    <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow>
                    <StackRow between>
                      <Skeleton
                        height={"4.2rem"}
                        width={"4.2rem"}
                        variant="circular"
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          mb: 4,
                        }}
                        animation="wave"
                      />
                      <Stack flex={1}>
                        <Skeleton
                          height={"30px"}
                          width={"100%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          height={"20px"}
                          width={"80%"}
                          sx={{
                            transform: "scale(1)",
                            transformOrigin: "0",
                            mb: 1,
                          }}
                          animation="wave"
                        />
                      </Stack>
                    </StackRow>

                    <Skeleton
                      height={"50px"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: 4,
                      }}
                      animation="wave"
                    />
                  </PaperBox> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                spacing={"1.7rem"}
                rowSpacing={"2.8rem"}
                columnSpacing={"1.7rem"}
              >
                {data.map((card, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <DashboardCard sx={{ flex: 1 }} {...card} />
                  </Grid>
                ))}

                <Grid item xs={12} md={12}>
                  {/* <PaperBox
                    padding={"2.2rem"}
                    sx={{
                      mb: 4,
                    }}
                  >
                    <Box sx={{ height: "25rem" }}>
                      <Headline4>Revenue</Headline4>
                      <Text>View and update your store details</Text>
                      <LineChart data={revenue} />
                    </Box>
                  </PaperBox> */}
                  <PaperBox padding={"2.2rem"}>
                    <Box sx={{ height: "25rem" }}>
                      <Headline4>Peak Time</Headline4>
                      {/* <Text>View and update your store details</Text> */}
                      <BarChart2 data={orders} />
                    </Box>
                  </PaperBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3.6}>
                      <PaperBox
                        sx={{
                          height: "39.3rem",
                          // padding: "1.14286rem",
                          // display: "flex",
                          // flexDirection: "column",
                          // gap: "1.47rem",
                        }}
                      >
                        <Stack gap={"1.43rem"}>
                          <StackRow center between>
                            <Headline3>Most Ordered</Headline3>
                            <StatusFilter
                              // label="Filter"
                              defaultValue={filtervalue}
                              menuList={[
                                { id: null, label: "Daily" },
                                { id: 1, label: "Weekly" },
                                { id: 2, label: "Monthly" },
                                { id: 3, label: "Yearly" },
                                { id: 4, label: "Last Year" },
                              ]}
                              onClick={(label) => setFilterValue(label)}
                            />
                          </StackRow>
                          {/* <Divider
                      sx={{
                        margin: "18px 0",
                      }}
                    /> */}
                          {/* {topMenu.length > 0 ? (
                      topMenu?.map((item) => {
                        return (
                          <ItemCard
                            imgSx={{
                              borderRadius: "50%",
                              width: "61.068px",
                              height: "55.415px",
                              objectFit: "cover",
                            }}
                            labelSx={{
                              fontSize: 14,
                              fontWeight: "500",
                            }}
                            sx={{
                              height: 40,
                              marginBottom: 3,
                            }}
                            title={item?.itemname}
                            subTitle={`${item?.total_count} dishes ordered`}
                            img={item?.item_image_url || FoodImg}
                          />
                        );
                      })
                    ) : (
                      <Text bold sx={{ marginLeft: "100px" }}>
                        No items yet
                      </Text>
                    )} */}
                          {topMenu ? (
                            <CustomDataGrid
                              hideFooter
                              rowId={"itemid"}
                              height={matches ? "28rem" : "30rem"}
                              name="top-items"
                              columns={columns}
                              dummyRows={topMenu}
                              loading={isLoading3 || isFetching3}
                              noActions
                              labelName={"Most Ordered"}
                              // sx={{'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }}}
                              autosizeOptions={{
                                columns: ["name", "status", "createdBy"],
                                includeOutliers: true,
                                includeHeaders: false,
                              }}
                              noDataScreen
                            />
                          ) : (
                            <Text>No data found.</Text>
                          )}
                        </Stack>
                      </PaperBox>
                    </Grid>
                    <Grid item xs={12} md={8.4}>
                      <Stack gap={2}>
                        <PaperBox
                          padding={"2.2rem"}
                          sx={{
                            // width: "47.71429rem",
                            display: "flex",
                            // width: '100%',
                            padding: "1.14286rem",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "1rem",
                            "& .MuiBox-root": {
                              padding: "0",
                              width: "100%",
                            },
                          }}
                        >
                          <StackRow center>
                            <Headline4 sx={{ marginBottom: "2rem" }}>
                              Order Summary
                            </Headline4>
                            {/* <CustomButton
                        sx={{
                          width: "100px",
                          position: "relative",
                        }}
                        variant="outlined"
                        textContent={"View All"}
                        onClick={() => navigate("/orders")}
                      
                      /> */}
                          </StackRow>
                          <StackRow gap={"1.14rem"}>
                            {summaryData.map((order, index) => (
                              <PaperBox
                                // p={"1.7rem"}
                                key={index}
                                sx={{
                                  display: "flex",
                                  padding: "1.14286rem 1.71429rem",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: "0.28571rem",
                                  flex: "1 0 0",
                                  "& .MuiBox-root": {
                                    padding: "0",
                                  },
                                }}
                              >
                                <Headline3>{order?.amount || 0}</Headline3>
                                <Text
                                  sx={{
                                    color: "var(--gray-500, #64748B)",
                                    fontFamily: "Inter",
                                    fontSize: "1rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "1.42857rem",
                                    letterSpacing: "-0.01rem",
                                    textWrap: "nowrap",
                                  }}
                                >
                                  {order?.name}
                                </Text>
                              </PaperBox>
                            ))}
                          </StackRow>
                        </PaperBox>

                        <Box sx={{ display: "flex", gap: "1rem" }}>
                          <PaperBox sx={{ height: "25rem", width: "31.64rem" }}>
                            <Box sx={{ height: "25rem", width: "27.64rem" }}>
                              <Headline4>Order Type</Headline4>
                              <PieChart data={revenue} />
                            </Box>
                          </PaperBox>
                          <PaperBox sx={{ height: "25rem", width: "31.64rem" }}>
                            <Box sx={{ height: "25rem", width: "27.64rem" }}>
                              <Headline4>Order Payment Type</Headline4>
                              <PieChart data={orderPaymentMethod} />
                            </Box>
                          </PaperBox>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </motion.div>
        ) : (
          <Publish />
        )}
      </>
    </>
  );
};

export default Dashboard;
