import { Box, Tooltip, Button, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import Text from "../components/typographyUI/Text";
import PageHeader from "../components/pageUI/PageHeader";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import ItemCard from "../components/pageUI/ItemCard";
import CloudPng from "../assets/icons/cloud.png";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import LocationFilter from "../components/pageUI/filters/LocationFilter";
import { motion } from "framer-motion";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ServerPaginationDataGrid from "../components/pageUI/ServerPaginationDataGrid";
import { useSelector } from "react-redux";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import RestoListFilter from "../components/pageUI/filters/RestoListFilter";
import axios from "axios";
import CustomerDetails from "../pages/CustomerDetails";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CustomFormDialog from "../components/CustomFormDialog";
import { toast } from "react-toastify";
import CustomeUploadExcel from "../components/CustomUploadExcel";
import CustomDialog from "../components/layoutUI/CustomDialog";
import { Close } from "@mui/icons-material";
import { Field, Form, Formik } from "formik";
import CustomTextFieldFormik from "../components/formUI/CustomTextFieldFormik";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import ExcelJS from "exceljs";


const MessStudents = () => {
  // const [tableData, setTableData] = useState([]);
  // const [search, setSearch] = useState("");
  //
  const [search,setSearch] = useState("");
  const outletData = useSelector((state) => state.auth.user);
  const [outletId, setOutletId] = useState(outletData.outletId);
  const [customerData, setCustomerData] = useState({});
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const [loading,setLoading] = useState(false);

  const outletIdNew = useSelector((state) => state.outledId.outletId);

  // const generateExcel = (_jsonData = []) => {
  //   const jsonData = _jsonData.map((d) => ({ ...d }));

  //   jsonData.forEach((d) => {
  //     delete d.campusid;
  //     delete d.outletid;
  //     delete d.customerauthuid;
  //     // delete d.message;
  //   });

  //   const ws = XLSX.utils.json_to_sheet(jsonData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Customers");

  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
  //   const data = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   saveAs(data, "Customers Data");
  // };
  const openCustomerDetails = (data) => {
    setShowCustomerDetails(true);
    setCustomerData(data);
    //
  };

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.Customer?.customerName}</Text>,
    },
   
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.Customer?.email}</Text>,
    },

    {
      field: "mobile",
      headerName: "Mobile number",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>{row?.Customer?.mobile}</Text>,
    },
  ]);
  const [isAsc, setIsAsc] = useState(false);

  const normalizeString = (str) => str?.trim()?.toLowerCase()?.replace(/\s+/g, ' ');

const getFilterData = (data) => {
  let _data = data;
  if (search) {
    const searchTerm = normalizeString(search);
    _data = _data?.filter((row) => 
      normalizeString(row?.Customer?.customerName)?.includes(searchTerm)
    );
  }
  return _data;
};

  const generateExcel = (_jsonData = []) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Students");

    // Adding header row
    if (_jsonData.length > 0) {
      const headers = Object.keys(_jsonData[0]);
      worksheet.addRow(headers);
    }

    // Adding data rows
    _jsonData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Apply bold font to the header row
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.font = { bold: true };
    });

    // Adjusting column widths
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    workbook.xlsx
      .writeBuffer()
      .then((excelBuffer) => {
        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(data, "Students_Data.xlsx");
      })
      .catch((error) => {
        console.error("Error generating Excel file:", error);
      });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"Students"}
          subTitle={"View and update your student details"}
        />
        <StackRow gap={"10px"} style={{display:"flex"}} between marginBottom="10px">
          {/* <LocationFilter /> */}
          <CustomSearchTextField
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <StackRow center gap="14px">
            {/* <RestoListFilter
              onClick={(label, id) => {
                setOutletId(id);
              }}
            /> */}
            {/* <StatusFilter
              label="Sort by"
              onClick={(_, id) => setIsAsc(id == 1)}
            /> */}
            <CustomButton onClick={() => setOpenStudentModal(true)}>
              + Add Student
            </CustomButton>
            <CustomButton
              textColor="#4F46E5"
              bgColor="#EEF2FF"
              variant="outlined"
              // startIcon={<CloudDownloadOutlinedIcon />}
              onClick={() => {
                setOpen(true);
                // axios
                //   .get(
                //     process.env.REACT_APP_BACKEND_BASE_URL +
                //       "customer/getCustomer/" +
                //       outletIdNew
                //   )
                //   .then((res) => {
                //     //
                //     // setTableData(res.data.data);
                //     generateExcel(res.data.data);
                //   })
                //   .catch((error) => {});
              }}
            ><img src={CloudPng} alt="upload" style={{width:"2rem", height:"2rem",marginRight:"0.5rem"}}/>
              Upload Excel
            </CustomButton>
            <CustomButton
              textColor="#4F46E5"
              bgColor="#EEF2FF"
              variant="outlined"
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={() => {
                setLoading(true);
                axios
                  .get(
                    process.env.REACT_APP_BACKEND_BASE_URL +
                      `mess/mwl/getWhitelistedUsersExcel/${outletId}`
                  )
                  .then((res) => {
                    // console.log("dataaa ",res?.data?.data);
                    generateExcel(res?.data?.data);
                  })
                  .catch((error) => {})
                  .finally(() => setLoading(false));
              }}
            >
              {loading ? "Generating..." : "Generate Excel"}
            </CustomButton>
          </StackRow>
        </StackRow>

        {outletIdNew && (
          <CustomDataGrid
            
            name="student-table"
            height={"490px"}
            columns={columns}
            rowId="customerAuthUID"
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              "mess/mwl/getWhitelistedUsers/" +
              outletIdNew
            }
            labelName={"Students"}
            deleteOption
            deleteUrl={process.env.REACT_APP_BACKEND_BASE_URL+`mess/mwl/deleteWhitelistedUser?outletId=${outletIdNew}&cuid=`}
            deleteItem
            noEditAction={true}
            // menuOnClick={({ id, label }, row) => {
            //   if (label === "Edit") {
            //     navigate("/mess/student/" + row?.customerAuthUID);
            //   }
            // }}
            filterFn={getFilterData}
          />
        )}
      </Box>
      {showCustomerDetails && (
        <CustomerDetails
          customerData={customerData}
          setShowCustomerDetails={setShowCustomerDetails}
        />
      )}

      {open && (
        <CustomeUploadExcel
          postUrl="pass here url"
          onSubmit={(file) => {
            toast.success("New Students Added");
          }}
          width={"600px"}
          open={open}
          setOpen={() => {
            setOpen(false);
          }}
        ></CustomeUploadExcel>
      )}

      {openStudentModal && (
        <CustomDialog dontClose open={true} sx={{ width: "80rem" }}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <PageHeader secondary title={`Student Details`} />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setOpenStudentModal(false)}
            />
          </Box>

          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              outletId: outletIdNew,
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = "Email is required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log("student details ", values);

              try {
                const res = await axios.post(
                  process.env.REACT_APP_BACKEND_BASE_URL +
                    "mess/mwl/whiteListUser",

                  values
                );
                console.log("API response:", res);
                setOpenStudentModal(false);
                if (res?.data?.success) {
                  toast.success("Student Added Successfully");
                  window.location.reload(); // Refresh the page
                }else{
                  toast.error(res?.data?.message);
                }
              } catch (error) {
                console.error("API error:", error);
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >
                  <CustomTextFieldFormik
                    label="Student Name"
                    placeholder="Enter Student Name"
                    name="name"
                  />
                  <CustomTextFieldFormik
                    label="Email"
                    placeholder="Enter Email"
                    required
                    name="email"
                  />
                  <CustomTextFieldFormik
                    label="Mobile Number"
                    placeholder="Enter Mobile Number"
                    name="phone"
                  />

                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      style={{
                        height: "35px",
                        // width: "8rem",
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </CustomButton>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </CustomDialog>
      )}
    </motion.div>
  );
};

export default MessStudents;
