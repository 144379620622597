import { Box, LinearProgress, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import PageHeader from "../PageHeader";
import StackRow from "../../layoutUI/StackRow";
import CustomSearchTextField from "../../formUI/CustomSearchTextField";
import DateFilter from "../filters/DateFilter";
import CustomFilter from "../filters/CustomFilter";
import StatusFilter from "../filters/StatusFilter";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import CustomSwitch from "../../formUI/CustomSwitch";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import moment from "moment";
import { Stack } from "@mui/system";
import queryString from "query-string";
import ButtonPng from "../../../assets/icons/Button.png";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import { acceptOrder, rejectOrder } from "../../../Services/orderService";
import CustomButton from "../../formUI/CustomButton";
import CustomLink from "../../formUI/CustomLink";
import { OrderStatus } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import RestoListFilter from "../filters/RestoListFilter";
import DateRangePicker from "../filters/DateRangePicker";
import axios from "axios";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";

const History = () => {
  const { user } = useSelector((state) => state.auth);
  const { petpoojaOrders } = useGetApis();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortType, setSortType] = useState("");
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModelLocal, setPaginationModelLocal] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const navigate = useNavigate();
  const [outletId, setOutletId] = useState(user?.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  useEffect(() => {
    if (startDate !== "Invalid Date" && endDate !== "Invalid Date") {
      setLoading(true);
      axios
        .post(
          petpoojaOrders +
            outletId +
            `?page=${
              paginationModelLocal.page + 1
            }&perPage=${10}&${queryString.stringify({
              orderType:
                type == "Dining"
                  ? "dinein"
                  : type === "Pickup"
                  ? "pickup"
                  : type === "Delivery"
                  ? "delivery"
                  : "",
              orderSequenceId: search,
            })}&startDate=${startDate}&endDate=${endDate}&sortType=${sortType}`,
          { orderStatusId: status }
        )
        .then((res) => {
          setLoading(false);
          setRowCount(res?.data?.meta?.totalCount);
          setRowData(res?.data?.data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [
    startDate,
    endDate,
    search,
    status,
    sortType,
    type,
    paginationModelLocal.page,
  ]);

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    reject: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  //
  const orderDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };
  const handleAccept = (orderId) => {
    acceptOrder(orderId)
      .then((res) => {})
      .catch((error) => {});
  };
  const handleReject = (orderId) => {
    rejectOrder(orderId)
      .then((res) => {})
      .catch((error) => {});
  };

  const columns = useMemo(() => [
    {
      field: "order_sequence_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 90,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.order_sequence_id}</Text>,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "50%",
          }}
          img={row?.photo}
          title={row?.customer_name}
          subTitle={`+ ${row?.mobile}`}
          center
          sx={{
            height: 36,
          }}
        />
      ),
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={
            row?.is_dine_in
              ? "Dine In"
              : row?.is_pick_up
              ? "Pick Up"
              : row?.is_delivery
              ? "Delivery"
              : "Unknown"
          }
          textColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.textColor
          }
          backgroundColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.backgroundColor
          }
        />
      ),
    },
    {
      field: "payment_method",
      headerName: "Payment",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text
            bold
            style={{
              backgroundColor: row?.isCashOrder ? "#EBEBEB" : "#F0FDF4",
              color: row?.isCashOrder ? "black" : "#15803D",
              padding:"0.3rem 1rem",
              borderRadius:"40px"
            }}
          >
            {row?.isCashOrder ? "Cash" : "Online"}
          </Text>
        </>
      ),
    },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {(row?.total_price).toFixed(2)}</Text>
        </>
      ),
    },
    {
      field: "Delivery time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "Order Status",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <OrderStatus
            row={row}
            chipColor={chipColor}
            // handleAccept={handleAccept}
            // handleReject={handleReject}
            noAction
          />
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomLink
            onClick={() => orderDetails(row?.order_id)}
            sx={{
              cursor: "pointer",
            }}
          >
            View Details
          </CustomLink>
        );
      },
    },
  ]);
  return (
    <Box p={0}>
      <PageHeader
        secondary
        title="PetPooja Orders"
        subTitle={"Your PetPooja orders are here."}
      />
      <StackRow between mb={"24px"}>
        <CustomSearchTextField
          placeholder="Search by Order ID"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <StackRow gap="20px">
          <DateRangePicker
            setFrom={setStartDate}
            setTo={setEndDate}
            fromDate={startDate}
            toDate={endDate}
            defaultValue={false}
          />
          <StatusFilter
            label="Sort by Time"
            menuList={[
              { id: 1, label: "Ascending" },
              { id: 2, label: "Descending" },
            ]}
            onClick={(label) => setSortType(label.toLowerCase())}
          />
          <StatusFilter
            label="Order Type"
            defaultValue={"All"}
            menuList={[
              { id: null, label: "All" },
              { id: 1, label: "Dining" },
              { id: 2, label: "Pickup" },
              { id: 3, label: "Delivery" },
            ]}
            onClick={(label) => setType(label)}
          />
          <StatusFilter
            label="Status"
            menuList={[
              { id: 100, label: "All" },
              { id: 123, label: "Accepted" },
              { id: 0, label: "Pending" },
              { id: 4, label: "Dispatched" },
              { id: 5, label: "Food Ready" },
              { id: 10, label: "Delivered" },
              { id: -2, label: "Reject" },
              { id: -1, label: "Cancelled" },
            ]}
            defaultValue={"All"}
            onClick={(label, id) => {
              if (id === 123) {
                setStatus([1, 2, 3]);
              } else if (id == 100) {
                setStatus([]);
              } else {
                setStatus([id]);
              }
            }}
          />
        </StackRow>
      </StackRow>
      <DataGrid
        name="petpooja_order"
        getRowId={(row) => row?.order_id}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        localeText={{
          noRowsLabel: "No PetPooja Orders Available",
        }}
        // autoHeight
        sx={{
          height: "580px",
          border: "none",
          "&  .MuiDataGrid-menuIcon,.MuiDataGrid-columnSeparator": {
            display: "none",
            cursor: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: 1,
            border: "1px #E2E8F0 solid",
            borderRadius: "9px",
            background: "#F8FAFC",
            color: "#64748B",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              borderBottom: "1px solid #EAECF0",
              background: "#FFFFFF",
              borderRadius: "1rem",
            },
            "& .MuiDataGrid-row.Mui-selected:hover": {
              borderBottom: "1px solid #EAECF0",
              backgroundColor: "#FFFFFF",
            },
          },
        }}
        columns={columns}
        rows={rowData}
        loading={loading}
        rowCount={rowCount || rowData?.length || 0}
        pageSizeOptions={[10]}
        pageSize={10}
        paginationModel={paginationModelLocal}
        page={paginationModelLocal.page}
        onPageChange={(page) => {
          setPage(page);

          return page;
        }}
        paginationMode="server"
        onPaginationModelChange={setPaginationModelLocal}
        initialState={{ pageSize: 10 }}
      />
    </Box>
  );
};

export default History;
