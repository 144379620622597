import { useQuery } from "react-query";
import StatusFilter from "./StatusFilter";
import axios from "axios";
import { outletUrl } from "../../../utils/urls";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const RestoListFilter = ({
  onClick = () => {},
  menuList: _menuList = null,
  matches = false
}) => {
  const { user } = useSelector((state) => state.auth);
  

  const { data, isLoading } = useQuery("outlet-list", () =>
    axios(`${outletUrl}${user?.outletId}`)
  );

  // console.log("data 20 ",data);

  return user?.isPrimaryOutlet ? (
    <StatusFilter
      defaultValue={`${user?.outletName || ""}, ${user?.campusId?.campusName}, ${user?.campusId?.cityId?.city} `}
      width={matches ? '20%' : "50rem"}
      labelWidth={matches ? '100%' : '50rem'}
      label={
        <>
          {" "}
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            Select Restaurant
          </span>{" "}
          {isLoading && <CircularProgress size={14} />}{" "}
        </>
      }
      onClick={onClick}
      menuList={
        data?.data?.data
          ? [
              {
                id: user?.outletId,
                label: `${user?.outletName || ""}, ${user?.campusId?.campusName}, ${user?.campusId?.cityId?.city}`,
              },
              ...data?.data?.data?.map((o) => ({
                id: o.outletId,
                label: `${o.outletName}, ${o.campusId?.campusName}, ${o.cityId?.city}`,
              })),
            ]
          : []
      }
    />
  ) : (<div></div>);
};

export default RestoListFilter;
