import { Box, Dialog, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../formUI/CustomButton";
import PaperBox from "./PaperBox";
import PageHeader from "../pageUI/PageHeader";
import StackRow from "./StackRow";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import CustomTextFieldFormik from "../formUI/CustomTextFieldFormik";
import { createRole, updateRole } from "../../Services/RoleService";
import CustomCheckbox from "../formUI/CustomCheckbox";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FormHelperText } from "@mui/material";
import { InputLabel } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoadingSate } from "../../Redux Store/Slices/loaderSlice";

const RoleDialog = ({
  open: _open = false,
  setOpen: _setOpen = () => {},
  buttonComp,
  width,
  children,
  onClose,
  title,
  subTitle,
  defaultData,
  refetch = () => 777,
  onSuccess = () => {},
}) => {
  const initialState = {
    role: "",
  };
  const [open, setOpen] = useState(_open || false);
  const [formData, setFormData] = useState(initialState);
  const [selected, setSelected] = useState([]);
  const restaurantData = useSelector((state) => state.auth.user);
  const [helperText, setHelperText] = useState(false);

  const [showOrdersSubRoles, setShowOrdersSubRoles] = useState(defaultData ? true : false);

  // console.log("defaultData ",defaultData)

  const dispatch = useDispatch();
  useEffect(() => {
    setOpen(_open);
  }, [_open]);
  useEffect(() => {
    defaultData && setFormData(defaultData);
    defaultData && setSelected(defaultData.access);
  }, [defaultData]);
  const closeHandler = async () => {
    setOpen(!open);
    _setOpen(!open);
    onClose?.();
    setFormData(initialState);
    setHelperText(false);
    defaultData ? setSelected(defaultData.access) : setSelected([]);
  };
  const addRole = (value) => {
    dispatch(setLoadingSate(true));
    createRole({
      role: value.role,
      access: selected,
      outletId: `${restaurantData?.outletId ? restaurantData.outletId : null}`,
      restaurantId: restaurantData?.restaurantId,
    })
      .then((response) => {
        dispatch(setLoadingSate(false));
        toast.success("Role added successfully");
        onSuccess();
        refetch();
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const editRole = (value) => {
    dispatch(setLoadingSate(true));
    updateRole(defaultData?.roleId, {
      role: value.role,
      access: selected,
    })
      .then((res) => {
        dispatch(setLoadingSate(false));
        onSuccess();
        refetch();
        toast.success("Role edited successfully");
      })
      .catch((err) => {
        refetch();
        // alert(8989)
      });
  };
  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          closeHandler();
        }}
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            width: width || "auto",
          },
        }}
      >
        <Formik
          initialValues={defaultData || formData}
          onSubmit={(value) => {
            if (!selected.length) {
              setHelperText(true);
            }
            if (defaultData && selected.length > 0) {
              editRole(value);

              closeHandler();
              return;
            } else if (selected.length > 0) {
              addRole(value);
              closeHandler();
              return;
            }
          }}
          validationSchema={Yup.object().shape({
            role: Yup.string()
              .required("Role is required")
              .min(3, "Role must be at least 3 characters")
              .max(30, "Role can't be more than 30 characters"),
            // access: Yup.array().min(1).required("At least one access required"),
          })}
        >
          <Form>
            <Box>
              <PaperBox padding={"24px"}>
                <PageHeader
                  secondary={true}
                  title={title}
                  subTitle={subTitle}
                  rightUi={
                    <StackRow center>
                      <div onClick={() => closeHandler()}>
                        <Close
                          sx={{
                            color: "#64748B",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </StackRow>
                  }
                />
                <Grid container spacing={"20px"}>
                  <Grid item xs={12}>
                    <CustomTextFieldFormik
                      label={"Role Name"}
                      placeholder="Enter Role Name"
                      required
                      name="role"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {/* {defaultData?.access} */}
                    <InputLabel style={{ color: "#0F172A", fontWeight: "500" }}>
                      Access{" "}
                      <Typography style={{ color: "red", display: "inline" }}>
                        *
                      </Typography>
                    </InputLabel>
                    {[
                      "Dashboard",
                      "Orders",
                      "pending",
                      "current",
                      "Live",
                      "Ready",
                      "history",
                      "cancelled",
                      "Menu",
                      "Customers",
                      "Outlets",
                      "KOT",
                      "Settings",
                    ].map((role) => {
                      if (role === "Orders") {
                        return (
                          <div key={role} style={{ marginBottom: "1rem" }}>
                            <CustomCheckbox
                              label={role}
                              checked={selected.includes(role)}
                              onChange={(e) => {
                                setShowOrdersSubRoles(e.target.checked);
                                let arr = !e.target.checked
                                  ? selected.filter((s) => s !== role)
                                  : [...selected, role];
                                setHelperText(arr.length === 0);
                                setSelected(arr);
                              }}
                            />
                            {showOrdersSubRoles && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginLeft: "1rem",
                                }}
                              >
                                {[
                                  "pending",
                                  "current",
                                  "Live",
                                  "Ready",
                                  "history",
                                  "cancelled",
                                ].map((subRole) => (
                                  <CustomCheckbox
                                    label={subRole}
                                    key={subRole}
                                    checked={selected.includes(subRole)}
                                    onChange={(e) => {
                                      let arr = !e.target.checked
                                        ? selected.filter((s) => s !== subRole)
                                        : [...selected, subRole];
                                      setHelperText(arr.length === 0);
                                      setSelected(arr);
                                    }}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      }
                      if (
                        ![
                          "pending",
                          "current",
                          "Live",
                          "Ready",
                          "history",
                          "cancelled",
                        ].includes(role)
                      ) {
                        return (
                          <div key={role} style={{ marginBottom: "0.5rem" }}>
                            <CustomCheckbox
                              label={role}
                              checked={selected.includes(role)}
                              onChange={(e) => {
                                let arr = !e.target.checked
                                  ? selected.filter((s) => s !== role)
                                  : [...selected, role];
                                setHelperText(arr.length === 0);
                                setSelected(arr);
                              }}
                            />
                          </div>
                        );
                      }

                      return null; // Skip rendering for subRoles outside the Orders section
                    })}
                  </Grid>
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                      marginLeft: "30px",
                      marginTop: "-6px",
                    }}
                  >
                    {helperText ? "At least one access required" : ""}
                  </FormHelperText>
                </Grid>
                <StackRow
                  sx={{
                    mt: "32px",
                  }}
                  justifyContent="flex-end"
                  gap="12px"
                >
                  <CustomButton
                    onClick={() => {
                      closeHandler();
                    }}
                    variant="outlined"
                    sx={{
                      minWidth: "180px",
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    sx={{
                      minWidth: "180px",
                    }}
                    onClick={() => {
                      if (!selected.length) {
                        setHelperText(true);
                      }
                    }}
                  >
                    {defaultData ? "Update" : "Submit"}
                  </CustomButton>
                </StackRow>
              </PaperBox>
            </Box>
          </Form>
        </Formik>
      </Dialog>
      {buttonComp?.({
        onClick: () => {
          setOpen(!open);
          _setOpen(!open);
        },
      })}
    </Box>
  );
};
export default RoleDialog;
