import React, { useEffect, useState } from "react";
import CustomMenu from "../../layoutUI/CustomMenu";
import StackRow from "../../layoutUI/StackRow";
import Text from "../../typographyUI/Text";
import DownSvg from "../../svgComponents/DownSvg";
import CustomButton from "../../formUI/CustomButton";
import { Button } from "@mui/material";

const StatusFilter = ({
  sx,
  label = "",
  onClick = () => {},
  menuList: _menuList = null,
  width,
  labelWidth,
  defaultValue = null,
}) => {
  const [menuList, setMenuList] = useState(
    _menuList || [
      { id: 1, label: "A to Z" },
      { id: 2, label: "Z to A" },
    ]
  );
  useEffect(() => {
    _menuList && setMenuList(_menuList);
  }, [_menuList]);
  const [value, setValue] = useState(null);
  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  return (
    <CustomMenu
      width={width || "10rem"}
      ButtonComp={(props) => (
        <StackRow
          between={true}
          component={Button}
          alignItems={"center"}
          sx={{
            border: "1px #CBD5E1 solid",
            padding: "5px 12px",
            borderRadius: "6px",
            width: labelWidth,
            ...sx,
          }}
          {...props}
        >
          <Text
            color="#334155"
            fontWeight={500}
            sx={{
              textTransform: "capitalize",
              whiteSpace: "nowrap",
              width: "100%",
              overflow: "hidden",
              ...{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "1.2rem"
              },
            }}
          >
            {label} {value && !label ? `${value || label}`: `: ${value || ""}`}
          </Text>
          <DownSvg />
        </StackRow>
      )}
      menuList={menuList}
      menuOnClick={({ label, id }) => {
        onClick(label, id);
        setValue(label);
      }}
    />
  );
};

export default StatusFilter;
