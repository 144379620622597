import { Box, Chip, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import PageHeader from "../PageHeader";
import StackRow from "../../layoutUI/StackRow";
import CustomSearchTextField from "../../formUI/CustomSearchTextField";
import DateFilter from "../filters/DateFilter";
import CustomFilter from "../filters/CustomFilter";
import StatusFilter from "../filters/StatusFilter";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import CustomSwitch from "../../formUI/CustomSwitch";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import moment from "moment";
import { Stack } from "@mui/system";
import ButtonPng from "../../../assets/icons/Button.png";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import { acceptOrder, rejectOrder } from "../../../Services/orderService";
import CustomButton from "../../formUI/CustomButton";
import CustomLink from "../../formUI/CustomLink";
import { OrderStatus } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import CustomMenu from "../../layoutUI/CustomMenu";
import EditSvg from "../../svgComponents/EditSvg";
import axios, { Axios } from "axios";
import RestoListFilter from "../filters/RestoListFilter";

const CurrentOrders = ({ getAllOrderDetails }) => {
  const { user } = useSelector((state) => state.auth);
  const { orderGetUrl, getLiveOrders } = useGetApis();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [dep, setDep] = useState(null);
  const [orderId, setOrderId] = useState([]);
  const [outletId, setOutletId] = useState(user?.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const [sortType, setSortType] = useState("");

  const navigate = useNavigate();

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    reject: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  const handlePrint = (order) => {
    var content = document.createElement("div");
    content.innerHTML = getHPrintHTML(order);

    var pri = document.getElementById("ifmcontentstoprint").contentWindow;

    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.write(`
    <style type="text/css">
      @media print {
        @page {
            margin-top: 0;
            margin-bottom: 0;
        }
        body {
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 !important;
        }
      }
    </style>
    `);

    pri.document.close();
    pri.focus();
    pri.print();
  };
  function getHPrintHTML(order) {
    // console.log("order ", order);
    const itemsHTML = order?.menu_item.map(
      (item) =>
        `<div>
          <div style="float:left; margin-bottom:1px;">
          ${
            order?.additional_instruction
              ? "[note] : " + order?.additional_instruction
              : ""
          }
          </div><br/>
          <div style="float:left; font-weight: bold;">${item?.itemname} ${
          item?.variation?.variation_name
            ? ` ${item?.variation?.variation_name}`
            : ""
        } x ${item?.quantity}  
          </div>
          <div style="float:right; font-weight: bold;">
            ₹${
              item?.variation?.variation_price
                ? item?.variation?.variation_price
                : item?.calculated_price
            }
          </div>
          <div style="clear:both"></div>
           ${
             item?.addons?.length
               ? item?.addons
                   ?.map(
                     (addon) =>
                       `<div>
                    <div style="float:left;">${addon?.name}</div>
                    <div style="float:right;">₹${addon?.price}</div>
                    <div style="clear:both"></div>
                  </div>`
                   )
                   .join("")
               : ""
           }
        </div>`
    );

    const _html = `
      <div style="border:1px solid lightgray; height:auto; width: 65mm; margin:auto; padding:5px">
        <div style="text-align:center; font-size:18px; font-weight:600">${
          user?.outletName
        }</div>
        <div style="text-align:center">${user?.GSTIN ? "GSTIN: " + user?.GSTIN : ""}</div>
        <div style="text-align:center">${user?.FSSAI_number ? "FSSAI No:" + user?.FSSAI_number : ""}</div>
        <div style="text-align:center">${user?.address}</div>
        <div style="text-align:center">Mobile: + 91 ${user?.mobile}</div>
        <div style="text-align:center">Email: ${user?.email}</div>
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>
          <div style="float:left">${order?.order_schedule_date}</div>
          <div style="float:right">${convertTo12HourFormat(
            order?.order_schedule_time,
            order?.preparation_time,
            order?.schedule_now
          )}</div>
          <div style="clear:both"></div>
        </div>
        <div style="clear: both;border-bottom: 1px dotted black; margin-block:5px"></div>
    
        <div>Order ID: #${order?.order_sequence_id}</div>
        
        <div style="margin-top:30px;">
          ${itemsHTML.join("")}
          <div style="clear:both"></div>
        </div>
         
        <div style="clear:both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div>
            <div style="float:left">Subtotal + Taxes:</div>
            <div style="float:right">₹ ${order?.base_price}</div>
          </div>
          ${
            order?.is_delivery || order?.is_pick_up
              ? `<div style="clear:both">
                   <div style="float:left">Packaging Charges:</div>
                   <div style="float:right">₹ ${
                     order?.packaging_charge || 0
                   }</div>
                 </div>`
              : ""
          }
          ${
            order?.is_delivery && !order?.is_pick_up
              ? `<div style="clear: both">
                   <div style="float:left">Delivery Charge:</div>
                   <div style="float:right">₹ ${
                     order?.deliverycharge || 0
                   }</div>
                 </div>`
              : ""
          }
          <div style="display: ${
            order?.isgstapplied ? `block` : `none`
          }; clear:both">
            <div style="float:left">Taxes:</div>
            <div style="float:right">₹ ${order?.food_gst || 0}</div>
          </div>
          <div style="clear: both">
            <div style="float:left">Convenience Fee:</div>
            <div style="float:right">₹ ${
              order?.convenience_total_amount?.toFixed(2) || 0
            }</div>
          </div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
        
        <div>
          <div style="float:left">Total:</div>
          <div style="float:right">₹ ${order?.amount?.toFixed(2)}</div>
          <div style="clear:both"></div>
        </div>
        
        <div style="clear: both; border-bottom: 1px dotted black; margin-block:5px"></div>
      </div>
    `;

    return _html;
  }
  function convertTo12HourFormat(time, preparationTime, addTime) {
    // Remove seconds
    time = time.split(":").slice(0, 2).join(":");

    // Convert time to total minutes
    let [hours, minutes] = time.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes;

    // Adjust time based on the boolean value
    if (addTime) {
      totalMinutes += preparationTime + 15;
    }

    // Calculate the new time
    let newHours = Math.floor(totalMinutes / 60) % 24;
    let newMinutes = totalMinutes % 60;

    // Determine AM or PM
    let period = newHours >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    if (newHours > 12) {
      newHours -= 12;
    } else if (newHours === 0) {
      newHours = 12;
    }

    // Format the time in 12-hour format
    let time12Hour = `${newHours}:${
      newMinutes < 10 ? "0" : ""
    }${newMinutes} ${period}`;

    return time12Hour;
  }
  const getFilterData = (data) => {
    let _data = data;
    if (type)
      _data = _data?.filter((row) =>
        type == "Dining"
          ? row.is_dine_in
          : type == "Pickup"
          ? row.is_pick_up
          : type == "Delivery"
          ? row.is_delivery
          : true
      );
    if (sortType == "Ascending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeA - dateTimeB;
      });
    }
    if (sortType == "Descending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeB - dateTimeA;
      });
    }
    if (search)
      _data = _data?.filter((row) => row.order_sequence_id.includes(search));
    if (orderId.length)
      _data = _data?.filter((o) => !orderId.includes(o.order_id));
    return _data;
  };

  const orderDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };
  const handleAccept = (orderId) => {
    axios
      .post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "order/order/dispatchOrder/" +
          orderId
      )
      .then((res) => {
        setOrderId((p) => [...p, orderId]);
        getAllOrderDetails();
        // setDep(Math.random());
      })
      .catch((error) => {});
  };
  const handleReject = (orderId) => {
    rejectOrder(orderId)
      .then((res) => {
        setOrderId((p) => [...p, orderId]);
        getAllOrderDetails();
      })
      .catch((error) => {});
  };
  const handleRefresh = () => setDep(Math.random());

  const columns = useMemo(() => [
    {
      field: "order_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 90,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.order_sequence_id}</Text>,
    },
    // {
    //   field: "customer",
    //   headerName: "Customer",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <ItemCard
    //       imgSx={{
    //         borderRadius: "50%",
    //       }}
    //       img={row?.photo}
    //       title={row?.customer_name}
    //       subTitle={`+ ${row?.mobile}`}
    //       center
    //       sx={{
    //         height: 36,
    //       }}
    //     />
    //   ),
    // },
    {
      field: "item",
      headerName: "Order Details",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }) => <ItemDetails menuItem={row?.menu_item} />,
    },
    {
      field: "Instruction",
      headerName: "Instruction",
      flex: 1,
      minWidth: 140,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>{row?.additional_instruction || "-"}</Text>
      ),
    },
    {
      field: "order preparing",
      headerName: "Order Preparing",
      flex: 1,
      minWidth: 220,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <OrderStatus
            row={row}
            chipColor={chipColor}
            handleAccept={handleAccept}
            handleReject={handleReject}
            title="Start Preparing"
            noChip
            button
          />
        );
      },
    },
    {
      field: "kot",
      headerName: "Print KOT",
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomButton
            onClick={() => handlePrint(row)}
            textContent={"Print KOT"}
            sx={{
              fontWeight: "400",
              zoom: "0.8",
            }}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomLink
            onClick={() => orderDetails(row?.order_id)}
            sx={{
              cursor: "pointer",
            }}
          >
            View Details
          </CustomLink>
        );
      },
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={
            row?.is_dine_in
              ? "Dine In"
              : row?.is_pick_up
              ? "Pick Up"
              : row?.is_delivery
              ? "Delivery"
              : "Unknown"
          }
          textColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.textColor
          }
          backgroundColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.backgroundColor
          }
        />
      ),
    },
    {
      field: "preparation_time",
      headerName: "Preparation Time",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>{row?.preparation_time} min</Text>
        </>
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomChip
    //       label={row.orderId.orderStatusId.text}
    //       textColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]?.textColor
    //       }
    //       backgroundColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]
    //           ?.backgroundColor
    //       }
    //     />
    //   ),
    // },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {(row?.total_price).toFixed(2)}</Text>
        </>
      ),
    },

    {
      field: "Delivery Time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          // Check if the adjusted time is past 12 AM
          // if (adjustedTime.isAfter(moment('12:00 AM', "h:mm A"))) {
          //   parsedDate2.add(1, 'day'); // Add 1 day to the date
          // }

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomSwitch
    //       value={row.status}
    //       label="In Stock"
    //       onChange={(e) => {
    //
    //         // mutatePut({ ...row, status: e });
    //       }}
    //     />
    //   ),
    // },
  ]);
  return (
    <Box p={0}>
      <PageHeader
        secondary
        title="Current Orders"
        subTitle={"Your current orders are here."}
      />
      <StackRow between mb={"24px"}>
        <CustomSearchTextField
          placeholder="Search by Order ID"
          value={search}
          onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
        />
        <StackRow gap="20px">
          <StatusFilter
            label="Sort by Time"
            menuList={[
              { id: 1, label: "Ascending" },
              { id: 2, label: "Descending" },
            ]}
            onClick={(label) => setSortType(label)}
          />
          <StatusFilter
            label="Order Type"
            defaultValue={"All"}
            menuList={[
              { id: null, label: "All" },
              { id: 1, label: "Dining" },
              { id: 2, label: "Pickup" },
              { id: 3, label: "Delivery" },
            ]}
            onClick={(label) => setType(label)}
          />
          {/* <StatusFilter
            menuList={[
              { id: 123, label: "Accepted" },
              { id: 0, label: "Pending" },
              { id: 4, label: "Dispatched" },
              { id: 5, label: "Food Ready" },
              { id: 10, label: "Delivered" },
              { id: -2, label: "Reject" },
              { id: -1, label: "Cancelled" },
            ]}
            onClick={(label, id) => {
              if (id === 123) {
                setStatus("1,2,3");
              } else {
                setStatus(id);
              }
            }}
          /> */}
        </StackRow>
      </StackRow>
      <CustomDataGrid
        name="current_orders"
        rowId={"order_id"}
        url={orderGetUrl + outletIdNew}
        columns={columns}
        labelName="Current Order"
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 300}
        // query={{
        //   orderType:
        //     type == "Dining"
        //       ? "dinein"
        //       : type == "Pickup"
        //       ? "pickup"
        //       : type == "Delivery"
        //       ? "delivery"
        //       : "",
        //   // orderStatusId: status,
        //   orderSequenceId: search,
        // }}
        // orderStatusId={status}
        noActions
        filterFn={getFilterData}
        //+"?outletId="+user.outletId ||+
        // dep={dep}
        // mappingData={(data) => {
        //   let _data = data;
        //   if (type)
        //     _data = _data?.filter((row) =>
        //       type == "Dining"
        //         ? row.is_dine_in
        //         : type == "Pickup"
        //         ? row.is_pick_up
        //         : type == "Delivery"
        //         ? row.is_delivery
        //         : true
        //     );
        //   if (search)
        //     _data = _data?.filter((row) =>
        //       row.order_sequence_id.includes(search)
        //     );

        //   return _data;
        // }}
      />
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
          visibility: "hidden",
        }}
      ></iframe>
    </Box>
  );
};

export default CurrentOrders;

const ItemDetails = ({ menuItem }) => {
  const order = menuItem.slice(0, 4).map((item, index) => (
    <React.Fragment key={item?.item_price * index}>
      <Text
        bold
        sx={{
          overflow: "hidden",
          width: "230px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {item?.quantity} x {item?.itemname}{" "}
        {item?.variation?.variation_name ? item.variation.variation_name : ""}
        {item?.addons?.length
          ? ", " + item?.addons.map((addon) => addon?.name).join(", ")
          : ""}
      </Text>
    </React.Fragment>
  ));
  return (
    <span>
      {order}{" "}
      {menuItem.length > 4 && (
        <Chip
          label={"+" + (menuItem?.length - 4) + " more"}
          sx={{ height: "28px", py: "8px" }}
        />
      )}
    </span>
  );
};
