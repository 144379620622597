import React, { useRef, useState, useCallback } from "react";
// Sub Components
import CustomDialog from "./layoutUI/CustomDialog";
import PageHeader from "./pageUI/PageHeader";
import StackRow from "./layoutUI/StackRow";
import CustomButton from "./formUI/CustomButton";
import ItemCard from "./pageUI/ItemCard";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";

// Typoraphy
import Text from "./typographyUI/Text";
import SmallText from "./typographyUI/SmallText";
import Title from "./typographyUI/Title";

// MUI Components
import { Close } from "@mui/icons-material";
import { Box, Chip, LinearProgress, Stack, Tooltip } from "@mui/material";

// Libs
import axios from "axios";
import { toast } from "react-toastify";
import { Workbook } from "exceljs";

// Utils
import Template from "../assets/images/Template.xlsx";
import { useGetApis } from "../hooks/useGetApis";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { authLogin } from "../Redux Store/Slices/auth";

// Images
import CloudPng from "../assets/icons/cloud2.png";
import ExcelPng from "../assets/icons/excel.jpg";
import AddPng from "../assets/icons/Add.png";
import CSVFileSvg from "./svgComponents/CSVFileSvg";

// Services
import {
  addMenuFromPetPooja,
  updatePetPoojaConfigs,
} from "../Services/menuService";

const CustomFormDialog = ({
  open = false,
  setOpen = () => {},
  postUrl,
  ...otherProps
}) => {
  const dispatch = useDispatch();

  const outletDetails = useSelector((state) => state.auth.user);
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const [file, setFile] = useState(null);
  const {
    spiceLevelUrl,
    attributeUrl,
    dietaryRestrictionsUrl,
    itemImageUrl,
    categoryGetUrl,
    subCategoryGetUrl,
    categoryUrl,
    subCategoryUrl,
  } = useGetApis();

  const [processStep, setProcessStep] = useState(0);
  //* 0 => Initial step
  //* 1 => Validation step
  //* 2 => uploading step
  //* 3 => Done

  const spiceLevelArray = useRef([]);
  const attributeArray = useRef([]);
  const dietaryRestrictionsArray = useRef([]);
  const categoryArray = useRef([]);
  const subCategoryArray = useRef([]);

  const inValidMenuItem = useRef([]);
  const uploadingMenuItem = useRef([]);
  const uploadingMenuItemFailureArray = useRef([]);
  const uploadingMenuItemSuccessCount = useRef(0);

  const onClose = () => {
    setOpen(!open);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const csvFiles = acceptedFiles.filter(
      (file) =>
        file.type === "text/csv" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    // Do something with the CSV files
    if (!csvFiles.length) {
      toast.warning("Please select .csv or .xlsx file only");
      return;
    }
    setFile(csvFiles[0]);

    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const uploadFile = async () => {
    let newCategoryArray = [];
    let newCategoryArrayExisted = [];
    let newSubCategoryArray = [];
    let newSubCategoryArrayExisted = [];
    if (!file) {
      toast.warn("Please select file to upload");
    } else {
      //
      //

      setProcessStep(1);
      const commonDataResponse = await Promise.all([
        axios.get(spiceLevelUrl),
        axios.get(attributeUrl),
        axios.get(dietaryRestrictionsUrl),
        axios.get(categoryGetUrl + outletIdNew),
        axios.get(subCategoryGetUrl + outletIdNew),
      ]);

      bindArray(commonDataResponse[0], spiceLevelArray);
      bindArray(commonDataResponse[1], attributeArray);
      bindArray(commonDataResponse[2], dietaryRestrictionsArray);
      bindArray(commonDataResponse[3], categoryArray);
      bindArray(commonDataResponse[4], subCategoryArray);

      console.log({
        spiceLevelArray,
        attributeArray,
        dietaryRestrictionsArray,
        categoryArray,
        subCategoryArray,
      });

      const reader = new FileReader();
      reader.onload = async function (e) {
        var data = e.target.result;
        data = new Uint8Array(data);

        //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        const workbookReader = await new Workbook().xlsx.load(data);
        let worksheet = workbookReader.getWorksheet(1);

        let rows = [];
        let firstRow = worksheet?.getRow(1);
        let keys = firstRow?.values;

        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber == 1) return;
          let values = row.values;
          let obj = {};

          for (let i = 1; i < keys.length; i++) {
            obj[keys[i]] = values[i];
          }
          rows.push({ ...obj, rowNumber });
        });

        // kke

        console.log({ newSubCategoryArray });

        //* Add images in array
        for (const image of worksheet.getImages()) {
          // fetch the media item with the data (it seems the imageId matches up with m.index?)
          const img = workbookReader.model.media.find(
            (m) => m.index === image.imageId
          );
          // fs.writeFileSync(`${image.range.tl.nativeRow}.${image.range.tl.nativeCol}.${img.name}.${img.extension}`, img.buffer);

          let _obj = rows.find(
            (f) => f?.rowNumber === image.range.tl.nativeRow + 1
          );
          if (_obj) {
            _obj.file = img.buffer;
          }
        }
        //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        const invalidDataArr = [];
        const validDataArr = [];

        rows.forEach((element) => {
          const validationResult = validateExcelObj(element);
          if (validationResult.validation.isValid) {
            validDataArr.push(element);
          } else {
            element.reason = validationResult.validation.reason;
            invalidDataArr.push(element);
          }
        });

        if (invalidDataArr.length > 0) {
          inValidMenuItem.current = [...invalidDataArr];
          setProcessStep(4);
          toast.error("Invalid data in excel sheet. Fix it and try again!");
        } else {
          // getApiToken(validDataArr);

          // Todo ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Start ~~~~~~~~~~~~~~~~~~~~~~~~

          rows.map((obj) => {
            const _category = categoryArray.current.find(
              (c) => c.parentCategoryName == obj.Category
            );
            const _subCategory = subCategoryArray.current.find(
              (c) => c.categoryname == obj["Sub Category"]
            );
            if (!_category)
              obj.Category != undefined &&
                newCategoryArray.push({
                  outletId: outletIdNew,
                  parentCategoryName: obj.Category,
                  category: [],
                });
            else
              newCategoryArrayExisted.push({
                parent_category_id: _category.parent_category_id,
                parentCategoryName: _category.parentCategoryName,
              });
            if (!_subCategory)
              obj["Sub Category"] != undefined &&
                newSubCategoryArray.push({
                  categoryname: obj["Sub Category"],
                  categoryid: null,
                  parent_category_id: _category?.parent_category_id || null,
                  parentCategoryName:
                    _category?.parentCategoryName || obj.Category || null,
                  outletId: outletIdNew,
                });
            else
              newSubCategoryArrayExisted.push({
                categoryname: _subCategory?.categoryname,
                categoryid: _subCategory?.categoryid,
                parent_category_id: _category?.categoryid || null,
                parentCategoryName:
                  _category?.parentCategoryName || obj.Category || null,
              });
          });

          newCategoryArray = newCategoryArray.filter((f,i) => newCategoryArray.findIndex(fi => fi.parentCategoryName === fi.parentCategoryName) === i);

          await makeMultipleHttpRequests(categoryUrl, newCategoryArray)
            .then((responses) => {
              // Handle responses
              console.log("All responses received:", responses);
              newCategoryArray = responses.map((d) => {
                let res = d.data[0];
                return {
                  parent_category_id: res.parent_category_id,
                  parent_category_id: res.parent_category_id,
                  parentCategoryName: res.parentCategoryName,
                };
              });
            })
            .catch((error) => {
              // Handle errors
              console.error("Error:", error);
            });

          console.log({ newCategoryArray, newCategoryArrayExisted });
          newCategoryArray = newCategoryArray.concat(newCategoryArrayExisted);
          newSubCategoryArray = newSubCategoryArray.map((sc) => {
            if (sc.parent_category_id == null) {
              const foundCategory = newCategoryArray.find(
                (c) => c.parentCategoryName == sc.parentCategoryName
              );
              if (foundCategory?.parent_category_id) {
                sc.parent_category_id = foundCategory.parent_category_id;
              }
            }
            return sc;
          });
          console.log({
            rows,
            newSubCategoryArray,
            newSubCategoryArrayExisted,
          });
          console.log({ newCategoryArray, newCategoryArrayExisted });

          newSubCategoryArray = newSubCategoryArray.filter((f,i) => newSubCategoryArray.findIndex(fi => fi.categoryname === fi.categoryname) === i);
          await makeMultipleHttpRequests(subCategoryUrl, newSubCategoryArray)
            .then((responses) => {
              // Handle responses
              console.log("All responses received:", responses);
              newSubCategoryArray = responses.map((d) => {
                let res = d.data[0];
                console.log({ res });
                return {
                  categoryid: res.categoryid,
                  categoryname: res.categoryname,
                  parent_category_id: res.parent_category_id,
                };
              });
              // newCategoryArray = responses.map((d) => {
              //   let res = d.data[0];
              //   return {
              //     parent_category_id: res.parent_category_id,
              //     parent_category_id: res.parent_category_id,
              //     parentCategoryName: res.parentCategoryName,
              //   };
              // });
            })
            .catch((error) => {
              // Handle errors
              console.error("Error:", error);
            });

          //Todo ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ END ~~~~~~~~~~~~~~~~~~~~~~~~

          uploadingMenuItem.current = [...validDataArr].map((item) => {
            let item_categoryid = newSubCategoryArray.find(
              (sc) => sc.categoryname == item["Sub Category"]
            )?.categoryid;

            
            if(item_categoryid) {
              item.item_categoryid = item_categoryid;
            } else {
              item_categoryid = subCategoryArray.current.find(f => f.categoryname === item['Sub Category'])?.categoryid;
              if (item_categoryid) {
                item.item_categoryid = item_categoryid;
              }
            }

            return item;
          });
          console.log({ uploadingMenuItem });
          // item_categoryid
          uploadItemToServer();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  async function uploadItemToServer() {
    setProcessStep(2);
    // uploadingMenuItemSuccessCount
    // uploadingMenuItemFailureCount

    for (const itemObject of uploadingMenuItem.current) {
      const postBody = {
        itemname: itemObject?.["Item Name"],
        price: +itemObject?.["Base Price"],
        itemdescription: itemObject?.["Item Description"],
        // item_image_url: itemObject?.['Item Media'],
        minimumpreparationtime: itemObject?.["Preparing Time"],
        kcal: itemObject?.["Kcal"],
        servinginfo: itemObject?.["Serving Info"],
        outletId: outletDetails?.outletId,
      };
      if (itemObject?.spice_level_id) {
        postBody.spice_level_id = itemObject.spice_level_id;
      }
      if (itemObject?.dietary_restriction_id) {
        postBody.dietary_restriction_id = itemObject.dietary_restriction_id;
      }
      if (itemObject?.attributeid) {
        postBody.attributeid = itemObject.attributeid;
      }
      if (itemObject?.item_categoryid) {
        postBody.item_categoryid = itemObject.item_categoryid;
      }

      try {
        const addMenuResponse = await addMenuFromPetPooja(postBody);
        if (addMenuResponse.data.success) {
          uploadingMenuItemSuccessCount.current =
            uploadingMenuItemSuccessCount.current + 1;
          if (itemObject?.file) {
            const formData = new FormData();
            formData.append("itemid", addMenuResponse?.data?.data?.itemid);
            formData.append(
              "file",
              uint8ArrayToFile(
                itemObject?.file,
                `${addMenuResponse?.data?.data?.itemid}.webp`
              )
            );
            try {
              const imageResponse = axios.post(itemImageUrl, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              });
            } catch (error) {}
          }
        } else {
          uploadingMenuItemFailureArray.current.push(itemObject);
        }
      } catch (error) {
        uploadingMenuItemFailureArray.current.push(itemObject);
      }
    }

    if (outletDetails?.publishProcessingStep <= 1) {
      const postData = {
        publishProcessingStep: 2,
      };
      //* update outlet
      const updateOutletConfigsResponse = await updatePetPoojaConfigs(
        postData,
        outletDetails?.outletId
      );

      if (updateOutletConfigsResponse?.data?.success) {
        dispatch(authLogin({ ...outletDetails, ...postData }));
      }
    }

    setProcessStep(3);
  }

  function validateExcelObj(excelObj) {
    const validation = {
      isValid: true,
      reason: [],
    };

    const requiredValidationFields = [
      {
        keyName: "Item Name",
        errorText: "Please add Item Name.",
      },
      {
        keyName: "Base Price",
        errorText: "Please add Base Price.",
      },
      {
        keyName: "Sub Category",
        errorText: "Please add Sub-Category.",
      },
      {
        keyName: "Attribute",
        errorText: "Please add Attribute"
      },
      {
        keyName: "Item Description",
        errorText: "Please add Item Description"
      },
      {
        keyName: "Preparing Time",
        errorText: "Please add Preparing Time"
      },
      {
        keyName: "Spice Level",
        errorText: "Please add Spice Level"
      },
      {
        keyName: "Kcal",
        errorText: "Please add Kcal"
      },
      {
        keyName: "Serving Info",
        errorText: "Please add Serving info"
      },
      // {
      //   keyName: "Item Media",
      //   errorText: "Please add Item Media"
      // }
    ];

    requiredValidationFields.forEach((fieldElement) => {
      if (
        !excelObj[fieldElement.keyName] ||
        excelObj[fieldElement.keyName] === ""
      ) {
        validation.isValid = false;
        validation.reason = [...validation.reason, fieldElement.errorText];
      }
    });

    if (excelObj?.Attribute) {
      let attributeObject = attributeArray?.current?.find(
        (f) =>
          f?.attribute?.toLowerCase() === excelObj?.Attribute?.toLowerCase()
      );
      if (attributeObject && attributeObject?.attributeid) {
        excelObj.attributeid = attributeObject.attributeid;
      } else {
        validation.isValid = false;
        validation.reason = [
          ...validation.reason,
          "Please add valid attribute.",
        ];
      }
    }
    if (excelObj?.["Spice Level"]) {
      let spiceLevelObject = spiceLevelArray?.current?.find(
        (f) =>
          f?.title?.toLowerCase() === excelObj?.["Spice Level"]?.toLowerCase()
      );
      if (spiceLevelObject && spiceLevelObject?.spice_level_id) {
        excelObj.spice_level_id = spiceLevelObject.spice_level_id;
      } else {
        validation.isValid = false;
        validation.reason = [
          ...validation.reason,
          "Please add valid spice level.",
        ];
      }
    }
    if (excelObj?.["Dietary restrictions"]) {
      let dietaryRestrictionsObject = dietaryRestrictionsArray?.current?.find(
        (f) =>
          f?.title?.toLowerCase() ===
          excelObj?.["Dietary restrictions"]?.toLowerCase()
      );
      if (
        dietaryRestrictionsObject &&
        dietaryRestrictionsObject?.dietary_restriction_id
      ) {
        excelObj.dietary_restriction_id =
          dietaryRestrictionsObject.dietary_restriction_id;
      } else {
        validation.isValid = false;
        validation.reason = [
          ...validation.reason,
          "Please add valid dietary restrictions.",
        ];
      }
    }

    return { validation, excelObj };
  }

  const downloadExcel = () => {
    const anchor = document.createElement("a");
    anchor.href = Template;
    anchor.download = "Menu Add Template.xlsx";
    anchor.click();
  };

  return (
    <CustomDialog
      open={open}
      width={"70rem"}
      setOpen={setOpen}
      onClose={onClose}
      dontClose
      {...otherProps}
      sx={{
        width: "75rem !important",
        maxWidth: "unset",
      }}
    >
      <PageHeader
        mb={"0 rem"}
        secondary
        title={"Upload Excel"}
        subTitle={"Add Menu Via Upload Excel"}
        rightUi={
          <>
            {processStep === 0 && (
              <StackRow center>
                <CustomButton
                  textColor="#4F46E5"
                  variant="text"
                  onClick={downloadExcel}
                  startIcon={
                    <img src={CloudPng} style={{ width: 20, height: 20 }} />
                  }
                >
                  Download Template
                </CustomButton>
                <div onClick={onClose}>
                  <Close
                    sx={{
                      color: "#64748B",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </StackRow>
            )}
          </>
        }
      />
      <Box
        sx={{
          transition: "0.4s",
          // height: "180px",
          overflow: "hidden",
        }}
      >
        {processStep === 0 && (
          <Box
            sx={{
              padding: "30px 16px",
              border: "1px dashed var(--gray-500, #64748B)",
              borderRadius: "8px",
              // background: isDragActive ? `url(${StripesGif})` : "transparent",
              background: isDragActive ? `whitesmoke` : "transparent",
              transform: isDragActive && "scale(0.95)",
              transition: "0.4s",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
              }}
            >
              <div {...getRootProps()}>
                <input
                  style={{ display: "none" }}
                  {...getInputProps()}
                  accept=".csv,application/vnd.ms-excel,.xlt,application/vnd.ms-excel,.xla,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template,.xlsm,application/vnd.ms-excel.sheet.macroEnabled.12,.xltm,application/vnd.ms-excel.template.macroEnabled.12,.xlam,application/vnd.ms-excel.addin.macroEnabled.12,.xlsb,application/vnd.ms-excel.sheet.binary.macroEnabled.12"
                />

                <Stack
                  justifyContent={"center"}
                  gap={"4px"}
                  alignItems={"center"}
                >
                  <img
                    src={file ? ExcelPng : AddPng}
                    style={{ width: 48, height: 48, marginBottom: "14px" }}
                  />
                  <SmallText>
                    {file?.name || "Upload Excel, Csv, max size 5MB each."}
                  </SmallText>
                  <StackRow>
                    <Text color="#0F172A" bold>
                      Drop your file here, or
                    </Text>
                    <Text
                      color="#4F46E5"
                      bold
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      click to browse
                    </Text>
                  </StackRow>
                </Stack>
              </div>
            </Box>
          </Box>
        )}
        {processStep > 0 && processStep <= 3 && (
          <Box
            sx={{
              transition: "0.3s",
              transitionDelay: "0.3s",
            }}
          >
            <StackRow sx={{ width: "100%" }} center spacing={2}>
              <Box mt={3.5} sx={{ mr: 2 }}>
                <CSVFileSvg />
              </Box>
              <Stack
                sx={{
                  flex: 1,
                  position: "relative",
                }}
                spacing={1}
              >
                <Title>Excel Validating</Title>
                <LinearProgress
                  variant={processStep > 1 ? "determinate" : "indeterminate"}
                  value={100}
                />
                <StackRow between={true}>
                  <Text fontSize={"14px"}></Text>
                  <Text fontSize={"14px"}>Validating...</Text>
                </StackRow>
              </Stack>
            </StackRow>
            <StackRow sx={{ width: "100%" }} center spacing={2}>
              <Box mt={3.5} sx={{ mr: 2 }}>
                <CSVFileSvg />
              </Box>
              <Stack
                sx={{
                  flex: 1,
                  position: "relative",
                }}
                spacing={1}
              >
                <Title>{file?.name || "File Name"}</Title>
                <LinearProgress
                  variant="determinate"
                  value={
                    processStep < 2
                      ? 0
                      : (
                          ((uploadingMenuItemSuccessCount.current +
                            uploadingMenuItemFailureArray.current?.length) /
                            uploadingMenuItem.current?.length) *
                          100
                        )?.toFixed()
                  }
                />
                <StackRow between={true}>
                  <Text fontSize={"14px"}>
                    {uploadingMenuItemSuccessCount.current +
                      uploadingMenuItemFailureArray.current?.length}{" "}
                    / {uploadingMenuItem.current?.length}
                  </Text>
                  <Text fontSize={"14px"}>
                    Uploading...{" "}
                    {processStep < 2
                      ? 0
                      : (
                          ((uploadingMenuItemSuccessCount.current +
                            uploadingMenuItemFailureArray.current?.length) /
                            uploadingMenuItem.current?.length) *
                          100
                        )?.toFixed()}
                    %
                  </Text>
                </StackRow>
              </Stack>
            </StackRow>
          </Box>
        )}
        {processStep > 3 && (
          <Box>
            <CustomDataGrid
              rowId={"id"}
              name="Validation"
              height={"350px"}
              columns={invalidItemColumn}
              labelName={"Items"}
              noActions
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  overflowX: "hidden",
                },
              }}
              // filterFn={getFilterData
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 300}
              dummyRows={inValidMenuItem.current.map((m, i) => ({
                ...m,
                id: i,
              }))}
            />
          </Box>
        )}
      </Box>
      <StackRow
        sx={{
          mt: "2.2rem",
        }}
        justifyContent="flex-end"
        gap="1rem"
      >
        {processStep === 0 && (
          <CustomButton
            onClick={() => {
              onClose();
            }}
            variant="outlined"
            sx={{
              minWidth: "180px",
            }}
          >
            Cancel
          </CustomButton>
        )}
        {processStep >= 3 ? (
          <CustomButton
            onClick={() => {
              onClose(true);
            }}
            sx={{
              minWidth: "180px",
            }}
          >
            Done
          </CustomButton>
        ) : (
          <CustomButton
            loading={Boolean(processStep > 0)}
            onClick={() => {
              uploadFile();
            }}
            sx={{
              minWidth: "180px",
            }}
          >
            Upload
          </CustomButton>
        )}
      </StackRow>
    </CustomDialog>
  );
};

export default CustomFormDialog;

const invalidItemColumn = [
  {
    field: "Item Name",
    headerName: "Item Name",
    flex: 1,
    minWidth: 250,
    sortable: false,
    renderCell: ({ row }) => (
      <ItemCard
        imgSx={{
          borderRadius: "8px",
          objectFit: "cover",
        }}
        img={row?.["Item Media"]}
        sx={{
          height: 36,
          margin: 0.8,
        }}
        title={row?.["Item Name"] || "--"}
        titleSx={{
          marginTop: "6px",
        }}
        starCount={0}
      />
    ),
  },
  {
    field: "Preparing Time",
    headerName: "Preparing Time",
    flex: 1,
    minWidth: 150,
    sortable: false,
    renderCell: ({ row }) => <Text>{row?.["Preparing Time"]}min</Text>,
  },
  {
    field: "Base Price",
    headerName: "Base Price",
    flex: 1,
    minWidth: 80,
    sortable: false,
    renderCell: ({ row }) => <Text bold>₹ {row?.["Base Price"]}</Text>,
  },
  {
    field: "Reason",
    headerName: "reason",
    flex: 1,
    minWidth: 100,
    sortable: false,
    renderCell: ({ row }) => {
      return <ReasonDetails reason={row?.["reason"]} />;
    },
  },
];

const ReasonDetails = ({ reason }) => {
  const order = reason?.slice(0, 3)?.map((item) => (
    <Text
      bold
      sx={{
        overflow: "hidden",
        width: "250px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {item}
    </Text>
  ));
  return (
    <span>
      {order}{" "}
      {reason?.length > 3 && (
        <Tooltip
          arrow
          title={
            <>
              {reason?.slice(3)?.map((item) => (
                <Text
                  bold
                  sx={{
                    overflow: "hidden",
                    width: "250px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item}
                </Text>
              ))}
            </>
          }
        >
          <Chip
            label={"+" + (reason?.length - 4) + " more"}
            sx={{ height: "28px", py: "8px" }}
          />
        </Tooltip>
      )}
    </span>
  );
};

function uint8ArrayToFile(uint8Array, fileName) {
  // Create a Blob with the MIME type 'image/webp'
  const blob = new Blob([uint8Array], { type: "image/webp" });

  // Create a File from the Blob
  const file = new File([blob], fileName, { type: "image/webp" });

  return file;
}

function bindArray(response, ref) {
  if (response && response.data && response.data?.success) {
    ref.current = response.data.data;
  }
}
async function makeHttpRequest(url, payload) {
  try {
    const response = await axios.post(url, payload);
    console.log(`Response for payload ${payload}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error for payload ${payload}:`, error.message);
    throw error;
  }
}

function makeMultipleHttpRequests(url, dataArray) {
  const requestPromises = dataArray.map((payload) =>
    makeHttpRequest(url, payload)
  );

  return Promise.all(requestPromises);
}
