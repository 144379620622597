import { Box, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import LogoPng from "../assets/images/logo.png";
import CustomTextField from "../components/formUI/CustomTextField";
import CustomButton from "../components/formUI/CustomButton";
import CustomLink from "../components/formUI/CustomLink";
import Headline1 from "../components/typographyUI/Headline1";
import Lead from "../components/typographyUI/Lead";
import PaperBox from "../components/layoutUI/PaperBox";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import CustomTextFieldFormik from "../components/formUI/CustomTextFieldFormik";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { loginUrl } from "../utils/urls";
import { useDispatch } from "react-redux";
import { authLogin } from "../Redux Store/Slices/auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setOutletId } from "../Redux Store/Slices/outletId";
import CustomCheckbox from "../components/formUI/CustomCheckbox";
import StackRow from "../components/layoutUI/StackRow";
import Text from "../components/typographyUI/Text";
import CustomCheckboxForLink from "../components/formUI/CustomCheckboxForLink";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabled1, setDisabled1] = useState(true);
  const login = (data) => axios.post(loginUrl, data);
  const { mutate, isLoading } = useMutation(login, {
    onSuccess: (res) => {
      toast.success("Logged in Successfully");
      // toast.success(res?.data?.data?.outletData?.outletId? "Outlet Logged in Successfully":"Restaurant Logged In Successfully");

      //
      if (res?.data?.data?.outletData) {
        // console.clear();
        //
        dispatch(setOutletId(res?.data?.data?.outletData?.outletId));
        let postData = {
          ...res?.data.data.outletData,
        };
        if (res?.data?.data?.outletStaffData?.data) {
          postData.outletStaffData = res?.data?.data?.outletStaffData?.data;
        }
        dispatch(authLogin({ ...postData }));

        const ACCESS = res?.data?.data?.outletStaffData?.data?.roleId?.access;
        if (ACCESS) {
          if (ACCESS.includes("Dashboard")) {
            navigate("/");
          } else {
            navigate(`/${ACCESS[0].toLowerCase()}`);
          }
        } else {
          navigate("/");
        }
      } else {
        toast.error("something went wrong please try again!!");
      }
    },
    onError: (err) => toast.error(err.response.data.error),
  });
  //
  const handleClick = () => {
    setShowPassword(!showPassword);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stack
        className="login-wrapper"
        sx={{
          minHeight: "100vh",
          display: "grid",
          placeContent: "center",
        }}
      >
        <PaperBox
          sx={{
            minWidth: 444,
            boxShadow:
              "0px 20px 25px -5px rgba(15, 23, 42, 0.10), 0px 10px 10px 0px rgba(15, 23, 42, 0.05)",
            borderRadius: "14px",
          }}
        >
          <Stack spacing={4} alignItems={"center"} mb={2}>
            <img
              src={LogoPng}
              style={{
                height: 100,
                width: 100,
                objectFit: "contain",
              }}
            />
            <Stack spacing={"10px"}>
              <Headline1 textAlign="center">Log in</Headline1>
              <Lead textAlign="center"> Hey there! Welcome back 👋</Lead>
            </Stack>
          </Stack>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
              password: Yup.string().required("Password is required"),
            })}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            <Form>
              <Stack gap={"20px"}>
                <CustomTextFieldFormik
                  label="Email"
                  name="email"
                  placeholder="enter your email"
                />
                <Stack>
                  {/* <CustomLink
                    textAlign="right"
                    sx={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </CustomLink> */}
                  <CustomTextFieldFormik
                    name="password"
                    label="Password"
                    onPaste={(e) => e.preventDefault()}
                    placeholder="enter your password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? (
                            <VisibilityIcon onClick={handleClick} />
                          ) : (
                            <VisibilityOffIcon onClick={handleClick} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Text variant="h6" style={{display:"flex",justifyContent:"flex-end",marginTop:"1rem"}}>
                    <Link
                      style={{
                        color: "#007BFF",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      to={"/login/forgot-password"}
                    >
                      Forgot Password
                    </Link>
                  </Text>
                  {/* <CustomCheckbox  label="Tnc"/> */}
                </Stack>
                {/* <CustomCheckboxForLink
                  label={"I agree to"}
                  onChange={(e) =>
                    e.target.checked ? setDisabled(false) : setDisabled(true)
                  }
                /> */}
                <Stack>
                  <CustomCheckbox
                    label={
                      <span>
                        I agree to the{" "}
                        <Link
                          style={{
                            color: "#007BFF",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          target="_blank"
                          to="https://zyxwtaeuvipslarwyrbe.supabase.co/storage/v1/object/public/documents/policy-files/MealPe_Terms%20of%20Use_AGC_Sept%2005,%202023.pdf"
                        >
                          Terms of Use
                        </Link>{" "}
                        and{" "}
                        <Link
                          style={{
                            color: "#007BFF",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          target="_blank"
                          to="https://zyxwtaeuvipslarwyrbe.supabase.co/storage/v1/object/public/documents/policy-files/MealPe_Privacy%20Policy_AGC_Sept%2005,%202023.pdf"
                        >
                          Privacy Policy
                        </Link>
                      </span>
                    }
                    onChange={(e) =>
                      e.target.checked
                        ? setDisabled1(false)
                        : setDisabled1(true)
                    }
                  />
                  <CustomCheckbox
                    label={
                      <span>
                        I have read and agree to the{" "}
                        <Link
                          style={{
                            color: "#007BFF",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          target="_blank"
                          to="https://zyxwtaeuvipslarwyrbe.supabase.co/storage/v1/object/public/documents/policy-files/MealPe_Restaurant_Onboarding%20Terms_AGC_Sept%2005,%202023.pdf"
                        >
                          Vendor agreement
                        </Link>
                      </span>
                    }
                    onChange={(e) =>
                      e.target.checked ? setDisabled(false) : setDisabled(true)
                    }
                  />
                </Stack>

                <CustomButton
                  type="submit"
                  loading={isLoading}
                  disabled={disabled || disabled1}
                >
                  Log in
                </CustomButton>

                <Text variant="h6" justifyContent={"center"}>
                  Don't have an account yet?{" "}
                  <Link
                    style={{
                      color: "#007BFF",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    to={"/create-outlet"}
                  >
                    Sign Up
                  </Link>
                </Text>
              </Stack>
            </Form>
          </Formik>
        </PaperBox>
      </Stack>
    </motion.div>
  );
};

export default Login;
