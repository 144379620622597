import { Box, Chip, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import PageHeader from "../PageHeader";
import StackRow from "../../layoutUI/StackRow";
import CustomSearchTextField from "../../formUI/CustomSearchTextField";
import DateFilter from "../filters/DateFilter";
import CustomFilter from "../filters/CustomFilter";
import StatusFilter from "../filters/StatusFilter";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import CustomSwitch from "../../formUI/CustomSwitch";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import { Stack } from "@mui/system";
import moment from "moment";
import ButtonPng from "../../../assets/icons/Button.png";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import { acceptOrder, rejectOrder } from "../../../Services/orderService";
import { OrderStatus } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import CustomLink from "../../formUI/CustomLink";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import axios from "axios";
import CustomMenu from "../../layoutUI/CustomMenu";
import RestoListFilter from "../filters/RestoListFilter";
import CustomButton from "../../formUI/CustomButton";

const LiveOrders = ({ getAllOrderDetails }) => {
  const { user } = useSelector((state) => state.auth);
  const { getLiveOrders } = useGetApis();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [dep, setDep] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState([]);
  const [sortType, setSortType] = useState("");

  const [outletId, setOutletId] = useState(user?.outletId);
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const navigate = useNavigate();

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });
  const getFilterData = (data) => {
    let _data = data;
    if (type)
      _data = _data?.filter((row) =>
        type == "Dining"
          ? row.is_dine_in
          : type == "Pickup"
          ? row.is_pick_up
          : type == "Delivery"
          ? row.is_delivery
          : true
      );
    if (sortType == "Ascending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeA - dateTimeB;
      });
    }
    if (sortType == "Descending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeB - dateTimeA;
      });
    }
    if (search)
      _data = _data?.filter((row) => row.order_sequence_id.includes(search));
    if (orderId.length)
      _data = _data?.filter((o) => !orderId.includes(o.order_id));
    return _data;
  };
  const orderDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };
  const handleAccept = (orderId) => {
    axios
      .post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "order/order/foodReadyOrder/" +
          orderId
      )
      .then((res) => {
        //
        setOrderId((p) => [...p, orderId]);
        getAllOrderDetails();
        // setDep(Math.random());
      })
      .catch((error) => {});
  };
  const handleReject = (orderId) => {
    rejectOrder(orderId)
      .then((res) => {})
      .catch((error) => {});
  };
  const columns = useMemo(() => [
    // {
    //   field: "change_status",
    //   headerName: "",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) =>
    //     (row?.order_status_text == "Accepted" ||
    //       row?.order_status_text == "Food Ready" ||
    //       row?.order_status_text == "Dispatch") && (
    //       <CustomMenu
    //         width={"140px"}
    //         menuList={[
    //           { id: 1, label: "Food Ready" },
    //           { id: 2, label: "Dispatch" },
    //           // { id: 3, label: "Delivered" },
    //         ]}
    //         menuOnClick={({ label, id: _id }) => {
    //           if (_id == 1) {
    //             axios
    //               .post(
    //                 process.env.REACT_APP_BACKEND_BASE_URL +
    //                   "order/order/foodReadyOrder/" +
    //                   `${row.order_id}`
    //               )
    //               .then((res) => {
    //

    //                 setDep(Math.random());
    //               })
    //               .catch((error) =>
    //           } else if (_id == 2) {
    //             axios
    //               .post(
    //                 process.env.REACT_APP_BACKEND_BASE_URL +
    //                   "order/order/dispatchOrder/" +
    //                   `${row.order_id}`
    //               )
    //               .then((res) => {
    //

    //                 setDep(Math.random());
    //               })
    //               .catch((error) =>
    //           } else {
    //             axios
    //               .post(
    //                 process.env.REACT_APP_BACKEND_BASE_URL +
    //                   "order/order/deliveredOrder/" +
    //                   `${row.order_id}`
    //               )
    //               .then((res) => {
    //

    //                 setDep(Math.random());
    //               })
    //               .catch((error) =>
    //           }
    //         }}
    //       />
    //     ),
    // },
    {
      field: "order_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 90,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.order_sequence_id}</Text>,
    },
    // {
    //   field: "customer",
    //   headerName: "Customer",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <ItemCard
    //       imgSx={{
    //         borderRadius: "50%",
    //       }}
    //       img={row?.photo}
    //       title={row?.customer_name}
    //       subTitle={`+ ${row?.mobile}`}
    //       center
    //       sx={{
    //         height: 36,
    //       }}
    //     />
    //   ),
    // },
    {
      field: "item",
      headerName: "Order Details",
      flex: 1,
      minWidth: 240,
      sortable: false,
      renderCell: ({ row }) => <ItemDetails menuItem={row?.menu_item} />,
    },
    {
      field: "actions",
      headerName: "Order Preparing",
      flex: 1,
      minWidth: 130,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <OrderStatus
            noRejectBtn
            row={row}
            chipColor={chipColor}
            handleAccept={handleAccept}
            title="Order Ready"
            noChip
            button
          />
          // <CustomButton
          //   sx={{
          //     fontWeight: "400",
          //     zoom: "0.8",
          //   }}
          //   loading={loading}
          //   onClick={() => {handleAccept(row?.order_id); setLoading(true)}}
          // >
          //   Order Ready
          // </CustomButton>
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomLink
            onClick={() => orderDetails(row.order_id)}
            sx={{
              cursor: "pointer",
            }}
          >
            View Details
          </CustomLink>
        );
      },
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={
            row?.is_dine_in
              ? "Dine In"
              : row?.is_pick_up
              ? "Pick Up"
              : row?.is_delivery
              ? "Delivery"
              : "Unknown"
          }
          textColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.textColor
          }
          backgroundColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.backgroundColor
          }
        />
      ),
    },
    {
      field: "preparation_time",
      headerName: "Preparation Time",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.preparation_time} min</Text>,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomChip
    //       label={row.orderId.orderStatusId.text}
    //       textColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]?.textColor
    //       }
    //       backgroundColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]
    //           ?.backgroundColor
    //       }
    //     />
    //   ),
    // },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {(row?.total_price).toFixed(2)}</Text>
        </>
      ),
    },
    {
      field: "Delivery Time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          // Check if the adjusted time is past 12 AM
          // if (adjustedTime.isAfter(moment('12:00 AM', "h:mm A"))) {
          //   parsedDate2.add(1, 'day'); // Add 1 day to the date
          // }

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
  ]);
  return (
    <Box p={0}>
      <PageHeader
        secondary
        title="Live Orders"
        subTitle={"Your live orders are here."}
      />
      <StackRow between mb={"24px"}>
        <CustomSearchTextField
          placeholder="Search by Order ID"
          value={search}
          onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
        />
        <StackRow gap="20px">
          <StatusFilter
            label="Sort by Time"
            menuList={[
              { id: 1, label: "Ascending" },
              { id: 2, label: "Descending" },
            ]}
            onClick={(label) => setSortType(label)}
          />
          <StatusFilter
            label="Order Type"
            defaultValue={"All"}
            menuList={[
              { id: null, label: "All" },
              { id: 1, label: "Dining" },
              { id: 2, label: "Pickup" },
              { id: 3, label: "Delivery" },
            ]}
            onClick={(label) => setType(label)}
          />
          {/* <StatusFilter
            menuList={[
              { id: 123, label: "Accepted" },
              { id: 0, label: "Pending" },
              { id: 4, label: "Dispatched" },
              { id: 5, label: "Food Ready" },
              { id: 10, label: "Delivered" },
              { id: -2, label: "Reject" },
              { id: -1, label: "Cancelled" },
            ]}
            onClick={(label, id) => {
              if (id === 123) {
                setStatus("1,2,3");
              } else {
                setStatus(id);
              }
            }}
          /> */}
        </StackRow>
      </StackRow>
      <CustomDataGrid
        name="upcoming_orders"
        rowId={"order_id"}
        url={getLiveOrders + outletIdNew}
        columns={columns}
        labelName="Live Order"
        filterFn={getFilterData}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 300}
        // query={{
        //   orderType:
        //     type == "Dining"
        //       ? "dinein"
        //       : type == "Pickup"
        //       ? "pickup"
        //       : type == "Delivery"
        //       ? "delivery"
        //       : "",
        //   // orderStatusId: status,
        //   orderSequenceId: search,
        // }}
        // orderStatusId={status}
        //+"?outletId="+user.outletId ||+
        dep={dep}
        noActions
        // depStr={dep}
        // mappingData={(data) => {
        //   return data?.filter((row) =>
        //     type == "Dining"
        //       ? row.is_dine_in
        //       : type == "Pickup"
        //       ? row.is_pick_up
        //       : type == "Delivery"
        //       ? row.is_delivery
        //       : true
        //   );
        //   return data?.filter((row) => row.order_sequence_id.includes(search));
        // }}
        // mappingData={(data) => {
        //   let _data = data;
        //   if (type)
        //     _data = _data?.filter((row) =>
        //       type == "Dining"
        //         ? row.is_dine_in
        //         : type == "Pickup"
        //         ? row.is_pick_up
        //         : type == "Delivery"
        //         ? row.is_delivery
        //         : true
        //     );
        //   if (search)
        //     _data = _data?.filter((row) =>
        //       row.order_sequence_id.includes(search)
        //     );

        //   return _data;
        // }}
      />
    </Box>
  );
};

export default LiveOrders;
const ItemDetails = ({ menuItem }) => {
  const order = menuItem.slice(0, 4).map((item, index) => (
    <React.Fragment key={item?.item_price * index}>
      <Text
        bold
        sx={{
          overflow: "hidden",
          width: "230px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {item?.quantity} x {item?.itemname}  {" "}
        {item?.variation?.variation_name ? item.variation.variation_name : ""}
        {item?.addons?.length
          ? ", " + item?.addons.map((addon) => addon?.name).join(", ")
          : ""}
      </Text>
    </React.Fragment>
  ));
  return (
    <span>
      {order}{" "}
      {menuItem.length > 4 && (
        <Chip
          label={"+" + (menuItem?.length - 4) + " more"}
          sx={{ height: "28px", py: "8px" }}
        />
      )}
    </span>
  );
};
