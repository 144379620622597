import { Box, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaperBox from "../../layoutUI/PaperBox";
import OutletsPng from "../../../assets/images/outlets.png";
import ItemCard from "../ItemCard";
import { useNavigate } from "react-router-dom";
import CustomChip from "../CustomChip";
import CustomMenu from "../../layoutUI/CustomMenu";
import StackRow from "../../layoutUI/StackRow";
import CustomLoader from "../../layoutUI/CustomLoader";
import { useGetApis } from "../../../hooks/useGetApis";
import axios from "axios";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import EditSvg from "../../svgComponents/EditSvg";

const OutletCard = ({
  img,
  title,
  subTitle,
  id = 0,
  logo,
  isPrimaryOutlet = false,
  isActive: _isActive = true,
  outletId = null,
}) => {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  const {deactiveOutlet} = useGetApis();
  // let isLoading = true;
  useEffect(() => {
    setActive(_isActive);
  }, [_isActive]);

  const { mutate, isLoading } = useMutation(
    (data) => {
      
      axios.post(deactiveOutlet + data.outletId ,{ isActive: !data.isActive} );
    },
    // {
    //   onSuccess: (res) => {
    //     toast.success("Deleted Successfully");
    //   },
    // }
  );
  
  return (
    <Grid item xs={12} md={4} >
      <PaperBox
        padding={"12px"}
        sx={{
          position: "relative",
          cursor: "pointer",
          transition: "0.3s",
          "&:active": {
            backgroundColor: "whitesmoke",

            transform: "scale(0.99)",
            boxShadow: "0 0 0 rgba(0,0,0,0.1)",
          },
          "&:hover": {
            // backgroundColor: "whitesmoke",

            boxShadow: "0 0 16px rgba(10,30,200,0.1)",
          },
        }}
      >
        <Box
          position={"absolute"}
          display={isLoading ? "grid" : 'none'}
          sx={{
            placeContent: "center",
            width: "90%",
            height: "75%",
          }}
        >
          <CustomLoader />
        </Box>
        {isPrimaryOutlet && (
          <Box
            sx={{
              position: "absolute",
              right: "16px",
              top: "20px",
              zIndex: 999,
            }}
          >
            <CustomChip
              backgroundColor="#ff5338"
              textColor={"white"}
              label="Primary Outlet"
              sx={
                {
                  // position : 'absolute'
                }
              }
            />
          </Box>
        )}
        {!isActive && (
          <Box
            sx={{
              position: "absolute",
              right: "16px",
              top: "20px",
              zIndex: 999,
            }}
          >
            <CustomChip
              backgroundColor="#525252"
              textColor={"white"}
              label="Deactived"
            />
          </Box>
        )}
        <img
          src={img || OutletsPng}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "20.627rem",
            marginBottom: "1.143rem",
            minHeight: "14.286rem",
            borderRadius: "0.714rem",
          }}
        />
        <StackRow between center>
          <ItemCard
            title={title}
            subTitle={subTitle}
            fontSizeSet={1}
            img={logo}
          />

          {!isPrimaryOutlet && (
            <CustomMenu
              verticalIcon
              width={"auto"}
              menuList={[
                { id: 1, label: `${isActive ? "Deactive" : "Active"}` },
                { id: 2, label: "Edit" },

              ]}
              menuOnClick={({ label, id: _id }) => {
                if (_id == 1) {
                  setActive(!isActive);
                  mutate({outletId: outletId, isActive: isActive} )
                }
                if (_id == 2){
                  navigate('/outlets/editOutlet/' + outletId)
                }
              }}
            />
          )}
        </StackRow>
      </PaperBox>
    </Grid>
  );
};

export default OutletCard;
