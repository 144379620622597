import { Box, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import Text from "../components/typographyUI/Text";
import PageHeader from "../components/pageUI/PageHeader";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import ItemCard from "../components/pageUI/ItemCard";
import CloudPng from "../assets/icons/cloud.png";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import LocationFilter from "../components/pageUI/filters/LocationFilter";
import { motion } from "framer-motion";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ServerPaginationDataGrid from "../components/pageUI/ServerPaginationDataGrid";
import { useSelector } from "react-redux";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import RestoListFilter from "../components/pageUI/filters/RestoListFilter";
import axios from "axios";
import CustomerDetails from "./CustomerDetails";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const Customers = () => {
  // const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  //

  const outletData = useSelector((state) => state.auth.user);
  const [outletId, setOutletId] = useState(outletData.outletId);
  const [customerData, setCustomerData] = useState({});
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);

  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const generateExcel = (_jsonData = []) => {
    const jsonData = _jsonData.map((d) => ({ ...d }));

    jsonData.forEach((d) => {
      delete d.campusid;
      delete d.outletid;
      delete d.customerauthuid;
      // delete d.message;
    });

    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Customers");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "Customers Data");
  };
  const openCustomerDetails = (data) => {
    setShowCustomerDetails(true);
    setCustomerData(data);
    //
  };

  const columns = useMemo(() => [
    {
      field: "customerName",
      headerName: "Name",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "50%",
          }}
          labelSx={{
            fontSize: 12,
          }}
          titleSx={{ marginTop: 1, "&:hover": { color: "green" } }}
          sx={{
            height: 36,
            cursor: "pointer",
          }}
          img={row?.photo}
          title={row.customername}
          onClick={() => openCustomerDetails(row)}
        />
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row.email || "-"}</Text>,
    },

    {
      field: "mobile",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>{row.mobile}</Text>,
    },

    {
      field: "status",
      headerName: "Campus",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row.campusname}</Text>,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row.city}</Text>,
    },
    {
      field: "ratings",
      headerName: "Ratings",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            display: "none",
          }}
          // img={row.item_image_url}
          sx={{
            height: 36,
            cursor: "pointer",
          }}
          titleSx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "120px", // Adjust this to a suitable value
          }}
          title={row.message}
          starCount={row.star}
          tooltip={
            <ItemCard
              imgSx={{
                display: "none",
              }}
              img={row.item_image_url}
              sx={{}}
              titleSx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "200px", // Adjust this to a suitable value
                whiteSpace: "break-spaces",
              }}
              title={row.message}
              starCount={row.star}
            />
          }
        />
      ),
    },
  ]);
  const [isAsc, setIsAsc] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box>
        <PageHeader
          secondary
          title={"Customers"}
          subTitle={"View and update your store details"}
        />
        <StackRow gap={"10px"} between center marginBottom="10px">
          {/* <LocationFilter /> */}
          <CustomSearchTextField
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <StackRow center gap="14px">
            {/* <RestoListFilter
              onClick={(label, id) => {
                setOutletId(id);
              }}
            /> */}
            <StatusFilter
              label="Sort by"
              onClick={(_, id) => setIsAsc(id == 1)}
            />
            
            <CustomButton
              textColor="#4F46E5"
              bgColor="#EEF2FF"
              variant="outlined"
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={() => {
                axios
                  .get(
                    process.env.REACT_APP_BACKEND_BASE_URL +
                      "customer/getCustomer/" +
                      outletIdNew
                  )
                  .then((res) => {
                    //
                    // setTableData(res.data.data);
                    generateExcel(res.data.data);
                  })
                  .catch((error) => {});
              }}
            >
              Generate Excel
            </CustomButton>
          </StackRow>
        </StackRow>

        {outletIdNew && (
          <ServerPaginationDataGrid
            // slotsProps={{ toolbar:()=>  <UploadButton/>  }}
            name="customers-table"
            height={"700px"}
            columns={columns}
            id="customerauthuid"

            
            url={
              process.env.REACT_APP_BACKEND_BASE_URL +
              "customer/getCustomer/" +
              outletIdNew
            }
            labelName={"Customers"}
            isSort={isAsc}
            noActions
            searchText={search}
            // getData={(data) => setTableData(data)}
          />
        )}
      </Box>
      {showCustomerDetails && (
        <CustomerDetails
          customerData={customerData}
          setShowCustomerDetails={setShowCustomerDetails}
        />
      )}
    </motion.div>
  );
};

export default Customers;
