import { Box, Chip, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import PageHeader from "../PageHeader";
import StackRow from "../../layoutUI/StackRow";
import CustomSearchTextField from "../../formUI/CustomSearchTextField";
import DateFilter from "../filters/DateFilter";
import CustomFilter from "../filters/CustomFilter";
import StatusFilter from "../filters/StatusFilter";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import CustomSwitch from "../../formUI/CustomSwitch";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useDispatch, useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import { Stack } from "@mui/system";
import moment from "moment";
import ButtonPng from "../../../assets/icons/Button.png";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import { acceptOrder, rejectOrder } from "../../../Services/orderService";
import { OrderStatus } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import CustomLink from "../../formUI/CustomLink";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import RestoListFilter from "../filters/RestoListFilter";
import touchMp3 from "../../../assets/images/touch.mp3";
import { removeRealTimeOrder } from "../../../Redux Store/Slices/realtimeOrder";

const PendingOrders = ({ getAllOrderDetails }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { orderUpcomingGetUrl, getPendingOrders } = useGetApis();
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState([]);
  const [newRowArray, setNewRowArray] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [outletId, setOutletId] = useState(user?.outletId);
  const [sortType, setSortType] = useState("");

  const audioRef = useRef(new Audio(touchMp3));
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const dummyRows = useSelector((state) => state.pendingOrders.pendingOrders);
  const pendingTOCancelOrders = useSelector(
    (state) => state.pendingOrders.pendingTOCancelOrders
  );

  // console.log("pending ", dummyRows);

  // useEffect(() => {
  //   const eventSource = new EventSource(
  //     "https://mealpe-testing-api.onrender.com/order/order/realtimePendingOrder/" + outletId
  //   );
  //   eventSource.onopen = (e) => {
  //
  //   };
  //   eventSource.onmessage = (e) => {
  //
  //   };
  //   eventSource.addEventListener("neworder", (e) => {
  //
  //     audioRef.current.play();
  //
  //     setNewRowArray([JSON.parse(e?.data), ...newRowArray]);
  //   });

  //   return () => {
  //     eventSource.close();
  //   };
  // }, [newRowArray]);

  const navigate = useNavigate();

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        setOrderData((prevData) => [...prevData]);
      }
    }, 30000); // 30 sec

    return () => clearInterval(intervalId);
  }, []);

  const orderDetails = (_orderId) => {
    navigate(`/orders/${_orderId}`);
  };
  const handleAccept = (_orderId) => {
    return acceptOrder(_orderId)
      .then((res) => {
        setOrderId((p) => [...p, _orderId]);
        getAllOrderDetails();
        dispatch(removeRealTimeOrder(_orderId));
      })
      .catch((error) => {});
  };
  const handleReject = (_orderId) => {
    return rejectOrder(_orderId)
      .then((res) => {
        setOrderId((p) => [...p, _orderId]);
        getAllOrderDetails();
        dispatch(removeRealTimeOrder(_orderId));
      })
      .catch((error) => {});
  };

  const getFilterData = (data) => {
    let _data = [...newRowArray, ...data];
    if (type)
      _data = _data?.filter((row) =>
        type == "Dining"
          ? row.is_dine_in
          : type == "Pickup"
          ? row.is_pick_up
          : type == "Delivery"
          ? row.is_delivery
          : true
      );
    if (sortType == "Ascending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeA - dateTimeB;
      });
    }
    if (sortType == "Descending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeB - dateTimeA;
      });
    }
    if (search)
      _data = _data?.filter((row) => row.order_sequence_id.includes(search));
    if (orderId.length)
      _data = _data?.filter((o) => !orderId.includes(o.order_id));
    // if (newRow) {
    //   _data.unshift(newRow);
    //
    // }
    // console.clear()

    // return _data
    return _data;
  };

  const columns = useMemo(() => [
    {
      field: "order_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 90,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => {
        //
        return <Text bold>{row?.order_sequence_id}</Text>;
      },
    },
    // {
    //   field: "customer",
    //   headerName: "Customer",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <ItemCard
    //       imgSx={{
    //         borderRadius: "50%",
    //       }}
    //       img={row?.photo}
    //       title={row?.customer_name}
    //       subTitle={`+ ${row?.mobile}`}
    //       center
    //       sx={{
    //         height: 36,
    //       }}
    //     />
    //   ),
    // },
    {
      field: "item",
      headerName: "Order Details",
      flex: 1,
      minWidth: 240,
      sortable: false,
      renderCell: ({ row }) => <ItemDetails menuItem={row?.menu_item} />,
    },
    {
      field: "actions",
      headerName: "Order Accept Reject",
      flex: 1,
      minWidth: 170,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <OrderStatus
            row={row}
            chipColor={chipColor}
            handleAccept={handleAccept}
            handleReject={handleReject}
            title="Accept"
            noChip
            button
          />
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomLink
            onClick={() => orderDetails(row?.order_id)}
            sx={{
              cursor: "pointer",
            }}
          >
            View Details
          </CustomLink>
        );
      },
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={
            row?.is_dine_in
              ? "Dine In"
              : row?.is_pick_up
              ? "Pick Up"
              : row?.is_delivery
              ? "Delivery"
              : "Unknown"
          }
          textColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.textColor
          }
          backgroundColor={
            chipColor[
              row?.is_dine_in
                ? "dineIn"
                : row?.is_pick_up
                ? "pickUp"
                : row?.is_delivery
                ? "foodready"
                : "Unknown"
            ]?.backgroundColor
          }
        />
      ),
    },
    {
      field: "schedule_type",
      headerName: "Schedule Type",
      flex: 1,
      minWidth: 130,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={row?.schedule_now ? "Now" : "Later"}
          textColor={
            chipColor[row?.schedule_now ? "dineIn" : "pending"]?.textColor
          }
          backgroundColor={
            chipColor[row?.schedule_now ? "dineIn" : "pending"]?.backgroundColor
          }
        />
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomChip
    //       label={row.orderId.orderStatusId.text}
    //       textColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]?.textColor
    //       }
    //       backgroundColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]
    //           ?.backgroundColor
    //       }
    //     />
    //   ),
    // },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {row?.total_price?.toFixed(2)}</Text>
        </>
      ),
    },
    {
      field: "date and time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          // Check if the adjusted time is past 12 AM
          // if (adjustedTime.isAfter(moment('12:00 AM', "h:mm A"))) {
          //   parsedDate2.add(1, 'day'); // Add 1 day to the date
          // }

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
  ]);
  return (
    <Box p={0}>
      <PageHeader
        secondary
        title="Pending Orders"
        subTitle={"Deliver these orders within 2 hours."}
      />
      <StackRow between mb={"24px"}>
        <CustomSearchTextField
          placeholder="Search by Order ID"
          value={search}
          onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
        />
        <StackRow gap="20px">
          <StatusFilter
            label="Sort By Time"
            menuList={[
              { id: 1, label: "Ascending" },
              { id: 2, label: "Descending" },
            ]}
            onClick={(label) => setSortType(label)}
          />
          <StatusFilter
            label="Order Type"
            defaultValue={"All"}
            menuList={[
              { id: null, label: "All" },
              { id: 1, label: "Dining" },
              { id: 2, label: "Pickup" },
              { id: 3, label: "Delivery" },
            ]}
            onClick={(label) => setType(label)}
          />
        </StackRow>
      </StackRow>
      <CustomDataGrid
        // dummyRows={dummyRows}
        dummyRows={dummyRows?.filter(
          (f) => !pendingTOCancelOrders?.some((s) => s?.orderId === f?.order_id)
        )}
        name="pending_orders"
        rowId={"order_id"}
        // url={getPendingOrders + user?.outletId}
        columns={columns}
        labelName="Pending Order"
        filterFn={getFilterData}
        noActions
        getRowHeight={() => "auto"}
        // getEstimatedRowHeight={() => 300}
      />
    </Box>
  );
};

export default PendingOrders;

const ItemDetails = ({ menuItem }) => {
  const order = menuItem.slice(0, 4).map((item, index) => (
    <React.Fragment key={item?.item_price * index}>
      <Text
        bold
        sx={{
          overflow: "hidden",
          width: "230px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {item?.quantity} x {item?.itemname} {" "}
        {item?.variation?.variation_name ? item.variation.variation_name : ""}
        {item?.addons?.length
          ? ", " + item?.addons.map((addon) => addon?.name).join(", ")
          : ""}
      </Text>
    </React.Fragment>
  ));
  return (
    <span>
      {order}{" "}
      {menuItem.length > 4 && (
        <Chip
          label={"+" + (menuItem?.length - 4) + " more"}
          sx={{ height: "28px", py: "8px" }}
        />
      )}
    </span>
  );
};
