import * as React from "react";
import { useField } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText"; // Import FormHelperText
import StackRow from "./layoutUI/StackRow";
import PaperBox from "./layoutUI/PaperBox";

const RadioButtonsGroup = ({ label, options, helperText, ...rest }) => {
  const [field, meta] = useField(rest);

  return (
    <FormControl
      component="fieldset"
      error={meta.touched && meta.error ? true : false}
    >
      {/* <FormLabel component="legend">{label}</FormLabel> */}
      <RadioGroup {...field} {...rest}>
        <StackRow gap={"16px"}>
          {options.map((option) => (
            <PaperBox key={option.value} padding={"8px 16px"}>
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            </PaperBox>
          ))}
        </StackRow>
      </RadioGroup>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
      {helperText && !meta.error && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RadioButtonsGroup;
