import { useField } from "formik";
import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import Title from "../typographyUI/Title";
import LargeText from "../typographyUI/LargeText";
import Text from "../typographyUI/Text";

const CustomTextFieldFormik = ({
  inputSx,
  label,
  sx,
  required,
  isDisabled,
  ...otherProps
}) => {
  const [field, meta] = useField(otherProps.name);
  const errorText = meta.touched && meta.error ? meta.error : "";

  return (
    <Stack spacing={0.5}>
      <Typography fontWeight={"500"} color={"#0F172A"}>
        {label}{" "}
        <span
          style={{
            display: required ? "inline" : "none",
            color: "#EF4444",
          }}
        >
          *
        </span>
      </Typography>
      <TextField
      autoComplete="off"
        sx={{
          "& .MuiFormHelperText-root": {
            // position: "absolute",
            // bottom: "-22px",
          },
          "& .MuiInputBase-root": {
            "&:hover": {
              borderColor: "#CBD5E1",
            },
            boxShadow: "0px 1px 2px 0px rgba(15, 23, 42, 0.06)",
            borderRadius: "10px",
            "& > input": {
              padding: "11px",
              "&::placeholder": {
                color: " #94A3B8",
                opacity: "1",
              },
            },
            ...inputSx,
          },
          "& fieldset": {
            borderColor: "#CBD5E1",
          },
          ...sx,
        }}
        // autoComplete="off"
        disabled={isDisabled}
        {...field}
        {...otherProps}
        error={meta.touched && meta.error ? true : false}
        helperText={errorText}
      />
    </Stack>
  );
};

export default CustomTextFieldFormik;
