import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../components/pageUI/PageHeader";
import PaperBox from "../components/layoutUI/PaperBox";
import CustomStepper from "../components/layoutUI/TabNaigator/CustomStepper";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import ItemCard from "../components/pageUI/ItemCard";
import Text from "../components/typographyUI/Text";
import Headline2 from "../components/typographyUI/Headline2";
import Headline4 from "../components/typographyUI/Headline4";
import { Email, Home, Phone } from "@mui/icons-material";
import StackRow from "../components/layoutUI/StackRow";
import CustomButton from "../components/formUI/CustomButton";
import PieChart from "../components/chartUI/PieChart";
import { useParams } from "react-router-dom";
import { useGetApis } from "../hooks/useGetApis";
import CustomLoader from "../components/layoutUI/CustomLoader";

const OrderSingle = () => {
  const { oId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { getOrderbyOrderId } = useGetApis();

  const columns = useMemo(() => [
    {
      field: "item",
      headerName: "Item",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          img={row?.Menu_Item?.item_image_url}
          title={`${row?.Menu_Item?.itemname} 
${row?.Variation?.name ? ` ${row.Variation.name}` : ""} 
${
  row?.addons?.length
    ? `, ${row.addons.map((addon) => addon?.name).join(", ")}`
    : ""
}`}
          center
          sx={{
            height: "auto",
          }}
        />
      ),
    },
    {
      field: "instruction",
      headerName: "Instruction",
      flex: 1,
      minWidth: 150,
      minWidth: 120,
      renderCell: ({ row }) => (
        <Text>{data?.additional_Instruction || "-"}</Text>
      ),
    },
    {
      field: "qty",
      headerName: "Quantity",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>x{row.quantity}</Text>,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text>₹ {row.itemPrice}</Text>,
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          ₹ {data?.totalPrice?.toFixed(2)}
        </Text>
      ),
    },
  ]);
  useEffect(() => {
    setActiveStep(data?.orderStatusId?.orderStatusId);
  }, [activeStep]);
  //
  const [data, setData] = useState({});
  // console.log("data ",data);

  // console.clear()

  const getStepperValue = (value) => {
    switch (value) {
      case 4:
        return 2;
      case 5:
        return 3;
      case 10:
        return 5;
      case 2:
        return 1;
      case 3:
        return 1;
      case -1:
        return 2;
      case -2:
        return 2;
      default:
        return value;
    }
  };
  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dateParts = inputDate?.split("-");
    if (dateParts?.length !== 3) {
      return "Invalid Date";
    }

    const year = dateParts[0];
    const month = months[parseInt(dateParts[1], 10) - 1];
    const day = dateParts[2];

    return `${day} ${month} ${year}`;
  }

  function formatTime(inputTime) {
    const timeParts = inputTime?.split(":");
    if (timeParts?.length !== 3) {
      // Handle invalid input time format
      return "Invalid Time";
    }

    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    let formattedTime = hours % 12; // Convert to 12-hour format
    if (formattedTime === 0) {
      formattedTime = 12; // Special case for 12:00 PM
    }

    const period = hours >= 12 ? "PM" : "AM";

    const formattedMinutes = minutes.toString().padStart(2, "0");

    if (minutes === 0) {
      return `${formattedTime}${period}`;
    } else {
      return `${formattedTime}:${formattedMinutes}${period}`;
    }
  }
  return (
    <>
      {
        <Box>
          <PageHeader
            secondary={true}
            title={`Order ID #${data.orderSequenceId}`}
            // subTitle={"Order / Order Details"}
            subTitle={
              `${
                data?.isDineIn
                  ? "Dine In"
                  : data?.isPickUp
                  ? "Pick Up"
                  : data?.isDelivery
                  ? "Delivery"
                  : "Unknown"
              }` +
              " / " +
              `${data?.isScheduleNow ? "Scheduled now" : "Scheduled later"}`
            }
            showBack={true}
          />
          <Grid
            container
            spacing="20px"
            sx={{
              display: !loading ? "none" : "flex",
            }}
          >
            <Grid item xs={9}>
              <Grid container spacing="20px">
                <Grid item xs={12}>
                  <PaperBox shadowed>
                    <CustomStepper
                      loading
                      steps={[
                        "Order Placed",
                        "Order Accepted",
                        "Preparing Order",
                        "Order Ready",
                      ]}
                      activeStep={data?.orderStatusId?.orderStatusId}
                    />
                  </PaperBox>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    height={"56px"}
                    width={"100%"}
                    sx={{
                      transform: "scale(1)",
                      transformOrigin: "0",
                      m: "auto",
                      mb: 4,
                    }}
                    animation="wave"
                  />
                  {new Array(8).fill(0).map((elt) => (
                    <Skeleton
                      height={"30px"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        m: "auto",
                        mb: 3,
                      }}
                      animation="wave"
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing="20px">
                <Grid item xs={12}>
                  <PaperBox shadowed>
                    <Stack
                      sx={{
                        alignItems: "center",
                      }}
                      spacing={"14px"}
                    >
                      <Skeleton
                        height={"56px"}
                        width={"56px"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          m: "auto",
                          borderRadius: "100%",
                        }}
                        animation="wave"
                      />
                      <Skeleton
                        height={"3ch"}
                        width={"120px"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          m: "auto",
                        }}
                        animation="wave"
                      />
                      <Skeleton
                        height={"2ch"}
                        width={"120px"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          m: "auto",
                        }}
                        animation="wave"
                      />
                      <Skeleton
                        height={"2ch"}
                        width={"120px"}
                        sx={{
                          transform: "scale(1)",
                          transformOrigin: "0",
                          m: "auto",
                        }}
                        animation="wave"
                      />
                    </Stack>
                  </PaperBox>
                </Grid>
                <Grid item xs={12}>
                  <PaperBox shadowed>
                    {/* <Skeleton
                      height={"12.5rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        m: "auto",
                      }}
                      animation="wave"
                    /> */}
                    <Skeleton
                      height={"2.5rem"}
                      width={"45%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                      }}
                      animation="wave"
                    />
                    <Divider sx={{ margin: "10px 0 10px 0" }} />

                    <Skeleton
                      height={"1.5rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: "5px",
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"1.5rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: "5px",
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"1.5rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: "5px",
                      }}
                      animation="wave"
                    />
                    <Skeleton
                      height={"1.5rem"}
                      width={"100%"}
                      sx={{
                        transform: "scale(1)",
                        transformOrigin: "0",
                        mb: "5px",
                      }}
                      animation="wave"
                    />
                  </PaperBox>
                </Grid>
                {/* <Grid item xs={12}>
              <CustomButton
                sx={{
                  width: "100%",
                }}
              >
                Refund
              </CustomButton>
            </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing="20px"
            sx={{
              display: loading ? "none" : "flex",
            }}
          >
            <Grid item xs={9}>
              <Grid container spacing="20px">
                <Grid item xs={12}>
                  <PaperBox shadowed>
                    <CustomStepper
                      steps={
                        [-1, -2].includes(data?.orderStatusId?.orderStatusId)
                          ? [
                              { label: "Order Placed", index: 0 },
                              {
                                label:
                                  data?.orderStatusId?.orderStatusId == -1
                                    ? "Order Cancelled"
                                    : "Order Rejected",
                                index: 1,
                              },
                            ]
                          : [
                              {
                                label: (
                                  <Stack>
                                    <Text
                                      sx={{
                                        fontWeight: "600",
                                        color: "#0F172A",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Order Placed
                                    </Text>
                                    <Text
                                      sx={{
                                        fontWeight: "600",
                                        color: "#0F172A",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {/* {`${data?.Order_Schedule?.[0]?.scheduleDate} ${data?.Order_Schedule?.[0]?.scheduleTime}`} */}
                                      {formatDate(
                                        data?.Order_Schedule?.[0]?.scheduleDate
                                      )}
                                      ,{" "}
                                      {formatTime(
                                        data?.Order_Schedule?.[0]?.scheduleTime
                                      )}
                                    </Text>
                                  </Stack>
                                ),
                                index: 0,
                              },
                              { label: "Order Accepted", index: 1 },
                              { label: "Preparing Order", index: 4 },
                              { label: "Order Ready", index: 5 },
                              { label: "Order Delivered", index: 10 },
                            ]
                      }
                      activeStep={getStepperValue(
                        data?.orderStatusId?.orderStatusId
                      )}
                    />
                  </PaperBox>
                </Grid>
                <Grid item xs={12}>
                  <CustomDataGrid
                    getLoading={setLoading}
                    name="order_single"
                    columns={columns}
                    url={getOrderbyOrderId + oId}
                    labelName="Order Details"
                    rowId={"orderItemId"}
                    noActions
                    mappingData={(data) => data?.Order_Item}
                    getData={(data) => setData(data)}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 400}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing="20px">
                <Grid item xs={12}>
                  <PaperBox shadowed>
                    <Stack
                      sx={{
                        alignItems: "center",
                      }}
                      spacing={"14px"}
                    >
                      <img
                        src={
                          data?.customerAuthUID?.photo ||
                          "https://placehold.co/600x600?text=profile"
                        }
                        style={{
                          borderRadius: "50%",
                          // border: "1px black solid",
                          height: "56px",
                          width: "56px",
                        }}
                      />
                      <Headline4>
                        {data?.customerAuthUID?.customerName}
                      </Headline4>
                      <StackRow center={true}>
                        <Email fontSize="16px" />
                        <Text>&nbsp; {data?.customerAuthUID?.email}</Text>
                      </StackRow>
                      <Box
                        sx={{
                          background: "#F1F5F9",
                          borderRadius: "17px",
                          padding: "5px 10px",
                          // width: '100%'
                        }}
                      >
                        <StackRow center={true} sx={{ alignItems: "center" }}>
                          <Phone fontSize="16px" />
                          <Text fontWeight={500}>
                            +91 {data?.customerAuthUID?.mobile}
                          </Text>
                        </StackRow>
                      </Box>
                      {/* <Box
                      sx={{
                        background: "#F1F5F9",
                        borderRadius: "15px",
                        padding: "5px",
                      }}
                    >
                      <StackRow center={true}>
                        <Text fontWeight={600}>Order Type:</Text>
                        <Text>
                          {data?.isDineIn
                            ? "Dine In"
                            : data?.isPickUp
                            ? "Pick Up"
                            : data?.isDelivery
                            ? "Delivery"
                            : "Unknown"}
                        </Text>
                      </StackRow>
                    </Box> */}
                      {data?.isDelivery && (
                        <Box
                          sx={{
                            background: "#F1F5F9",
                            borderRadius: "7px",
                            padding: "5px",
                          }}
                        >
                          <StackRow sx={{ alignItems: "center" }}>
                            <Home />
                            <Text>{data?.DeliveryAddress?.[0]?.address}</Text>
                          </StackRow>
                        </Box>
                      )}
                    </Stack>
                  </PaperBox>
                </Grid>
                <Grid item xs={12}>
                  {/* <PaperBox shadowed>
                    <Stack
                      sx={
                        {
                          // alignItems: "center",
                        }
                      }
                      spacing={"14px"}
                    >
                      <Text
                        bold
                        sx={{
                          fontWeight: "600",
                        }}
                      >
                        Summary
                      </Text>
                      <Box
                        sx={{
                          position: "relative",
                          top: "32px",
                        }}
                      >
                        <Box
                          sx={{
                            height: "200px",
                            transform: "scale(2)",
                          }}
                        >
                          <PieChart />
                        </Box>
                      </Box>
                      <Stack spacing={"10px"}>
                        <Text bold>Pizza (70%)</Text>
                        <LinearProgress
                          variant="determinate"
                          value={12}
                          sx={{
                            height: "6px",
                            borderRadius: "50px",
                          }}
                        />
                      </Stack>
                      <Stack spacing={"10px"}>
                        <Text bold>Pizza (51%)</Text>
                        <LinearProgress
                          variant="determinate"
                          value={12}
                          sx={{
                            height: "6px",
                            borderRadius: "50px",
                            background: "#E2E8F0",
                            "& .MuiLinearProgress-bar": {
                              background: "#FACC14",
                            },
                          }}
                        />
                      </Stack>
                      <Stack spacing={"10px"}>
                        <Text bold>Pizza (40%)</Text>
                        <LinearProgress
                          variant="determinate"
                          value={40}
                          sx={{
                            height: "6px",
                            borderRadius: "50px",
                            background: "#E2E8F0",
                            "& .MuiLinearProgress-bar": {
                              background: "#2BD4BD",
                            },
                          }}
                        />
                      </Stack>
                    </Stack>
                  </PaperBox> */}
                  <PaperBox shadowed>
                    <Stack>
                      <Text
                        bold
                        sx={{
                          fontWeight: "600",
                        }}
                      >
                        Price Summary:
                      </Text>
                      <Divider sx={{ margin: "10px 0 10px 0" }} />
                      <StackRow between>
                        <Text
                          sx={{
                            color: "var(--gray-800, #1E293B)",
                            fontFamily: "Inter",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "1.42857rem",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          {data?.Transaction?.isGSTApplied
                            ? "Subtotal:"
                            : "Subtotal + Taxes:"}
                        </Text>
                        <Text
                          sx={{
                            color: "var(--gray-900, #0F172A)",
                            textAlign: "right",
                            fontFamily: "Inter",
                            fontSize: "1.14286rem",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "1.57143rem",
                            letterSpacing: "-0.01143rem",
                          }}
                        >
                          ₹ {data?.Transaction?.itemTotalPrice}
                        </Text>
                      </StackRow>
                      {(data.isDelivery || data?.isPickUp) && (
                        <>
                          {" "}
                          <StackRow between>
                            <Text
                              sx={{
                                color: "var(--gray-800, #1E293B)",
                                fontFamily: "Inter",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "1.42857rem",
                                letterSpacing: "-0.01rem",
                              }}
                            >
                              Packaging Charges:
                            </Text>
                            <Text
                              sx={{
                                color: "var(--gray-900, #0F172A)",
                                textAlign: "right",
                                fontFamily: "Inter",
                                fontSize: "1.14286rem",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "1.57143rem",
                                letterSpacing: "-0.01143rem",
                              }}
                            >
                              ₹ {data?.Transaction?.packagingCharge || 0}
                            </Text>
                          </StackRow>
                          {data.isDelivery && !data?.isPickUp && (
                            <StackRow between>
                              <Text
                                sx={{
                                  color: "var(--gray-800, #1E293B)",
                                  fontFamily: "Inter",
                                  fontSize: "1rem",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "1.42857rem",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                Delivery Charge:
                              </Text>
                              <Text
                                sx={{
                                  color: "var(--gray-900, #0F172A)",
                                  textAlign: "right",
                                  fontFamily: "Inter",
                                  fontSize: "1.14286rem",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "1.57143rem",
                                  letterSpacing: "-0.01143rem",
                                }}
                              >
                                ₹ {data?.Transaction?.deliveryCharge || 0}
                              </Text>
                            </StackRow>
                          )}
                        </>
                      )}
                      {data?.Transaction?.isGSTApplied && (
                        <StackRow between>
                          <Text
                            sx={{
                              color: "var(--gray-800, #1E293B)",
                              fontFamily: "Inter",
                              fontSize: "1rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "1.42857rem",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            Taxes:
                          </Text>
                          <Text
                            sx={{
                              color: "var(--gray-900, #0F172A)",
                              textAlign: "right",
                              fontFamily: "Inter",
                              fontSize: "1.14286rem",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "1.57143rem",
                              letterSpacing: "-0.01143rem",
                            }}
                          >
                            ₹ {data?.Transaction?.foodGST || 0}
                          </Text>
                        </StackRow>
                      )}
                      <StackRow between>
                        <Text
                          sx={{
                            color: "var(--gray-800, #1E293B)",
                            fontFamily: "Inter",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "1.42857rem",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          Convenience Fee:
                        </Text>
                        <Text
                          sx={{
                            color: "var(--gray-900, #0F172A)",
                            textAlign: "right",
                            fontFamily: "Inter",
                            fontSize: "1.14286rem",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "1.57143rem",
                            letterSpacing: "-0.01143rem",
                          }}
                        >
                          ₹{" "}
                          {data?.Transaction?.convenienceTotalAmount?.toFixed(
                            2
                          ) || 0}
                        </Text>
                      </StackRow>
                      <StackRow between>
                        <Text
                          sx={{
                            color: "var(--gray-800, #1E293B)",
                            fontFamily: "Inter",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "1.42857rem",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          Total:
                        </Text>
                        <Text
                          sx={{
                            color: "var(--gray-900, #0F172A)",
                            textAlign: "right",
                            fontFamily: "Inter",
                            fontSize: "1.14286rem",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "1.57143rem",
                            letterSpacing: "-0.01143rem",
                          }}
                        >
                          ₹ {data?.totalPrice?.toFixed(2)}
                        </Text>
                      </StackRow>
                    </Stack>
                  </PaperBox>
                </Grid>
                {/* <Grid item xs={12}>
             <CustomButton
               sx={{
                 width: "100%",
               }}
             >
               Refund
             </CustomButton>
           </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  );
};

export default OrderSingle;
