import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import Joyride from "react-joyride";
import Tour from "reactour";
import { useSelector } from "react-redux";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function CustomMenu({
  ButtonComp,
  children,
  menuList = [],
  menuOnClick = () => {},
  width,
  className = "",
  stepGuide = false,
  verticalIcon = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { user } = useSelector((state) => state.auth);
  const [runTour, setRunTour] = useState(true);
  // const [open1, setOpen1] = useState(false);
  // setTimeout(() => setOpen1(true), 3000)
  const hasShownMenuStep = localStorage.getItem('hasShownMenuStep');
  // const hasShownMenuStep = 0;
  const driverObj = driver({
    showProgress: true,
    steps: [
      { element: '.custom-menu-item-3', popover: { title: 'Add Menu Items', description: 'Click here to add menu items.', side: "left", align: 'start' }},
      { element: '.custom-menu-item-1', popover: { title: 'Fetch Menu from PetPooja', description: 'Click here to fetch menu from PetPooja.', side: "left", align: 'start' }},
      { element: '.custom-menu-item-4', popover: { title: 'Upload Excel', description: 'Click here to add menu items through excel.', side: "left", align: 'start' }},
    ]
  });
  if(hasShownMenuStep && user?.publishProcessingStep==1){
    driverObj.destroy();
  }
  if(open && stepGuide && user?.publishProcessingStep==1 && !hasShownMenuStep){
    setTimeout(() => driverObj.drive(), 500)
    // driverObj.drive()
    
  }
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const sxStyles = {
    menu: {
      "& .MuiPaper-root": {
        borderRadius: "0.75rem",
        p: "0.75rem",
        boxShadow: "0px 1px 1rem #E2E8F0",
        border: "1px solid #E2E8F0",
        width: width || "15.25rem",
      },
    },
    menuItem: {
      borderRadius: "0.875rem",
      mt: 1,
    },
    menuItemSpan: {
      fontSize: "0.875rem",
      fontWeight: "500",
    },
  };

  const buttonCompProps = {
    onClick: handleClick,
  };
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      setRunTour(false);
    }
  };

  return (
    <Box className={className} sx={{}}>
      {ButtonComp ? (
        <div style={{ cursor: "pointer" }}>{ButtonComp(buttonCompProps)}</div>
      ) : (
        <IconButton {...buttonCompProps}>
          {verticalIcon ? <MoreVertIcon/> : <MoreHorizIcon />}
        </IconButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={sxStyles.menu}
      >
        {children}
        {menuList.map(({ icon, label, id, className }) => (

            <MenuItem
              key={id}
              onClick={() => {
                handleClose();
                menuOnClick({ label, id });
              }}
              sx={sxStyles.menuItem}
              className={className}
            >
              <Stack direction="row" gap={1} alignItems={"center"}>
                {icon}
                <span style={sxStyles.menuItemSpan}>{label}</span>
              </Stack>
            </MenuItem>
        ))}
      </Menu>

      {/* <Tour
        steps={steps}
        isOpen={runTour}
        onRequestClose={() => setRunTour(false)}
        disableKeyboardNavigation={true}
      /> */}
      {/* <Joyride
        steps={steps}
        debug={true}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        styles={{
          overlay: {
            width: "100vw", // Set a fixed width for the overlay
          },
        }}
        callback={handleJoyrideCallback}
      /> */}
    </Box>
  );
}
