import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  children,
  sx,
  bgColor,
  borderColor,
  textColor,
  textContent,
  ...otherProps
}) => {
  return (
    <LoadingButton
      variant="contained"
      sx={{
        borderColor: borderColor,
        background: bgColor,
        color: textColor,
        textTransform: "inherit",
        fontSize: "1.143rem",
        borderRadius: "0.571rem",
        padding: "0.786rem 1.143rem",
        height: "3.143rem",
        boxShadow: "none",
        lineHeight: "1",
        ...sx,
      }}
      {...otherProps}
    >
      {textContent || children}
    </LoadingButton>
  );
};

export default CustomButton;
