import React, { useRef, useState, useCallback } from "react";
// Sub Components
import CustomDialog from "./layoutUI/CustomDialog";
import PageHeader from "./pageUI/PageHeader";
import StackRow from "./layoutUI/StackRow";
import CustomButton from "./formUI/CustomButton";
import ItemCard from "./pageUI/ItemCard";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";

// Typoraphy
import Text from "./typographyUI/Text";
import SmallText from "./typographyUI/SmallText";
import Title from "./typographyUI/Title";

// MUI Components
import { Close } from "@mui/icons-material";
import { Box, Chip, LinearProgress, Stack, Tooltip } from "@mui/material";

// Libs
import axios from "axios";
import { toast } from "react-toastify";
import { Workbook } from "exceljs";

// Utils
import StudentTemplate from "../assets/images/StudentTemplate.xlsx";
import { useGetApis } from "../hooks/useGetApis";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { authLogin } from "../Redux Store/Slices/auth";

// Images
import CloudPng from "../assets/icons/cloud2.png";
import ExcelPng from "../assets/icons/excel.jpg";
import AddPng from "../assets/icons/Add.png";
import CSVFileSvg from "./svgComponents/CSVFileSvg";

const CustomeUploadExcel = ({
  open = false,
  setOpen = () => {},
  postUrl,
  ...otherProps
}) => {
  const dispatch = useDispatch();

  const outletDetails = useSelector((state) => state.auth.user);
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const [loading,setLoading] = useState(false);

  const [file, setFile] = useState(null);
  const {} = useGetApis();

  const [processStep, setProcessStep] = useState(0);

  const onClose = () => {
    setOpen(!open);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const csvFiles = acceptedFiles.filter(
      (file) =>
        file.type === "text/csv" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    // Do something with the CSV files
    if (!csvFiles.length) {
      toast.warning("Please select .csv or .xlsx file only");
      return;
    }
    setFile(csvFiles[0]);

    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const uploadFile = async () => {
    if (!file) {
      toast.warn("Please select file to upload");
    } else {
      const reader = new FileReader();
      reader.onload = async function (e) {
        var data = e.target.result;
        data = new Uint8Array(data);

        //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        const workbookReader = await new Workbook().xlsx.load(data);
        let worksheet = workbookReader.getWorksheet(1);

        let rows = [];
        let firstRow = worksheet?.getRow(1);
        let keys = firstRow?.values;

        let emailColumnIndex = -1;
        for (let i = 1; i < keys.length; i++) {
          if (keys[i] === "email") {
            emailColumnIndex = i;
            break;
          }
        }

        if (emailColumnIndex === -1) {
          toast.error("Email column not found in the uploaded file.");
          return;
        }

        let hasInvalidEmail = false;

        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber == 1) return;
          let values = row.values;
          let obj = {};

          for (let i = 1; i < keys.length; i++) {
            obj[keys[i]] = values[i];
          }

          if (!values[emailColumnIndex]) {
            hasInvalidEmail = true;
          }

          rows.push({ ...obj, rowNumber });
        });

        if (hasInvalidEmail) {
          toast.error("One or more rows have an empty email field.");
          return;
        }

        console.log("Extracted rows: ", { userObjs: rows, outletIdNew });

        // API call to upload data
        setLoading(true);
        try {
          const response = await axios.post(
            process.env.REACT_APP_BACKEND_BASE_URL + "mess/mwl/whiteListUsers",
            { userObjs: rows, outletId: outletIdNew }
          );
          console.log("excel res ", response);
          if (response?.data?.success) {
            toast.success("File uploaded successfully!");
            window.location.reload();
            onClose();
          } else {
            toast.error(response?.data?.message);
            onClose();
          }
        } catch (error) {
          toast.error("An error occurred while uploading the file.");
          console.error("Upload error: ", error);
        }finally{
          setLoading(false);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const downloadExcel = () => {
    const anchor = document.createElement("a");
    anchor.href = StudentTemplate;
    anchor.download = "Students Add Template.xlsx";
    anchor.click();
  };

  return (
    <CustomDialog
      open={open}
      width={"70rem"}
      setOpen={setOpen}
      onClose={onClose}
      dontClose
      {...otherProps}
      sx={{
        width: "75rem !important",
        maxWidth: "unset",
      }}
    >
      <PageHeader
        mb={"0 rem"}
        secondary
        title={"Upload Excel"}
        subTitle={"Add Students Via Upload Excel"}
        rightUi={
          <>
            {processStep === 0 && (
              <StackRow center>
                <CustomButton
                  textColor="#4F46E5"
                  variant="text"
                  onClick={downloadExcel}
                  startIcon={
                    <img src={CloudPng} style={{ width: 20, height: 20 }} />
                  }
                >
                  Download Template
                </CustomButton>
                <div onClick={onClose}>
                  <Close
                    sx={{
                      color: "#64748B",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </StackRow>
            )}
          </>
        }
      />
      <Box
        sx={{
          transition: "0.4s",
          // height: "180px",
          overflow: "hidden",
        }}
      >
        {processStep === 0 && (
          <Box
            sx={{
              padding: "30px 16px",
              border: "1px dashed var(--gray-500, #64748B)",
              borderRadius: "8px",
              // background: isDragActive ? `url(${StripesGif})` : "transparent",
              background: isDragActive ? `whitesmoke` : "transparent",
              transform: isDragActive && "scale(0.95)",
              transition: "0.4s",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
              }}
            >
              <div {...getRootProps()}>
                <input
                  style={{ display: "none" }}
                  {...getInputProps()}
                  accept=".csv,application/vnd.ms-excel,.xlt,application/vnd.ms-excel,.xla,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template,.xlsm,application/vnd.ms-excel.sheet.macroEnabled.12,.xltm,application/vnd.ms-excel.template.macroEnabled.12,.xlam,application/vnd.ms-excel.addin.macroEnabled.12,.xlsb,application/vnd.ms-excel.sheet.binary.macroEnabled.12"
                />

                <Stack
                  justifyContent={"center"}
                  gap={"4px"}
                  alignItems={"center"}
                >
                  <img
                    src={file ? ExcelPng : AddPng}
                    style={{ width: 48, height: 48, marginBottom: "14px" }}
                  />
                  <SmallText>
                    {file?.name || "Upload Excel, Csv, max size 5MB each."}
                  </SmallText>
                  <StackRow>
                    <Text color="#0F172A" bold>
                      Drop your file here, or
                    </Text>
                    <Text
                      color="#4F46E5"
                      bold
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      click to browse
                    </Text>
                  </StackRow>
                </Stack>
              </div>
            </Box>
          </Box>
        )}
      </Box>
      <StackRow
        sx={{
          mt: "2.2rem",
        }}
        justifyContent="flex-end"
        gap="1rem"
      >
        {processStep === 0 && (
          <CustomButton
            onClick={() => {
              onClose();
            }}
            variant="outlined"
            sx={{
              minWidth: "180px",
            }}
          >
            Cancel
          </CustomButton>
        )}
        {processStep >= 3 ? (
          <CustomButton
            onClick={() => {
              onClose(true);
            }}
            sx={{
              minWidth: "180px",
            }}
          >
            Done
          </CustomButton>
        ) : (
          <CustomButton
            loading={Boolean(processStep > 0)}
            onClick={uploadFile}
            sx={{
              minWidth: "180px",
            }}
          >
          {loading ? "Uploading..." : "Upload"}
          </CustomButton>
        )}
      </StackRow>
    </CustomDialog>
  );
};

export default CustomeUploadExcel;
