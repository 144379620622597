import { Typography } from "@mui/material";
import React from "react";

const Lead = ({ children, sx, ...otherProps }) => {
  return (
    <Typography color={"#475569"} fontSize={"1.2rem"} sx={sx} {...otherProps}>
      {children}
    </Typography>
  );
};

export default Lead;
