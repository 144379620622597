import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";
import { useState } from "react";

const DateRangePicker = ({
  setFrom = () => {},
  setTo = () => {},
  fromDate = "",
  toDate = "",
  defaultValue = false,
}) => {
  const date = new Date()
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [clearEndDate, setClearEndDate] = useState(false);
  const [clearStartDate, setClearStartDate] = useState(false);
  const [dateFrom, setDateFrom] = useState(dayjs(new Date(date?.getFullYear(), date?.getMonth(), 1)))
  const [dateTo, setDateTo] = useState(dayjs());


  
  React.useEffect(() => {
    if (clearEndDate) {
      setTo('')
      setEndDate('')
      const timeout = setTimeout(() => {
        setClearEndDate(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    if (clearStartDate) {
      setFrom('');
      setStartDate('')
      const timeout = setTimeout(() => {
        setClearStartDate(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [clearEndDate, clearStartDate])


  return (
    <Box sx={{"& > .MuiStack-root": {padding: '0', margin: '0', overflow: 'unset', minWidth: '0'}, "& .MuiStack-root> .MuiTextField-root": {minWidth:'0'}}} display={"flex"} flexDirection={"row"} gap={"10px"}>
      <LocalizationProvider  dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            sx={{ "&  > .MuiInputBase-root": { height: "37px", width: '15rem' } }}
            value={defaultValue ? dateFrom : startDate}
            label="From"
            onChange={(e) => {
              setStartDate(dayjs(e).format("YYYY-MM-DD"));
              setFrom(dayjs(e).format("YYYY-MM-DD"));
            }}
            slotProps={{
            field: { clearable: true, onClear: () => setClearStartDate(true) },
            textField: {
              size: "small",
              error: false,
            }
            }}
          />
          
           
        </DemoContainer>
      </LocalizationProvider>
      <LocalizationProvider  dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker", "DatePicker"]}>
      <DatePicker
            sx={{ "&  > .MuiInputBase-root": { height: "37px", width: '15rem' } }}
            label="To"
            value={defaultValue ? dateTo : endDate}
            minDate={dayjs(startDate)}
            disabled={(!startDate && !defaultValue) ? true : false}
            onChange={(e) => {
              setEndDate(dayjs(e).format("YYYY-MM-DD"));
              setTo(dayjs(e).format("YYYY-MM-DD"));
            }}
            slotProps={{
              field: { clearable: true, onClear: () => setClearEndDate(true) },
              textField: {
                size: "small",
                error: false,
              }
            }}
          />
      </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangePicker;
